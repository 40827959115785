import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Pagination from 'components/board/board_pagination';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';


import { BATCH_ACTIONS, CANDIDATES_PER_RESULTS_PAGE } from 'helper/constants';
import { checkHasCompanyFeature, checkHasUserPermission } from 'helper/commonFunctions';
import { appCtx } from 'components/appStore';

import { invitationsCtx } from '../../../../invitationsStore';
import { assessmentSettingsCtx } from '../../../../../store';

import CandidateRow from './CandidateRow';

import useStyles from './styles';

const CandidatesTable = observer(() => {
    const {
        handleChangePage, allCandidates,
        totalCount, page,
        handleChangeSortCategory,
        sortOrder, sortCategory,
        selectedCandidates,
        toggleSelectAll,
        isLoading, isLoadingLocal
    } = useContext(invitationsCtx);
    const { companyTags, audition } = useContext(assessmentSettingsCtx);
    const { company } = useContext(appCtx);
    const classes = useStyles();
    const totalPagesCount = Math.ceil(totalCount / CANDIDATES_PER_RESULTS_PAGE);

    const sortProps = {
        onSort: handleChangeSortCategory,
        sortOrder,
        sortCategory
    };

    const hasCompanyFeature = checkHasCompanyFeature(company, BATCH_ACTIONS);

    const message = checkHasUserPermission(company, audition.permissions, 'sendMessage');
    const reject = checkHasUserPermission(company, audition.permissions, 'reject');
    const reset = checkHasUserPermission(company, audition.permissions, 'reset');


    const allSelected = Object.keys(allCandidates).every(key => Boolean(selectedCandidates[allCandidates[key].candidateId]));

    const onSelect = (e) => {
        e.stopPropagation();
        toggleSelectAll(allSelected);
    };


    return (
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCellHead
                            label="Name"
                            className={clsx(classes.tableCellHead, totalCount && hasCompanyFeature && classes.tableCellName)}
                            labelComponent={(
                                <>
                                    {
                                        Boolean(Object.keys(allCandidates).length)
                                        && hasCompanyFeature
                                        && (message || reject || reset) && (
                                            <Checkbox
                                                color="primary"
                                                checked={allSelected}
                                                onClick={onSelect}
                                                className={classes.checkbox}
                                            />
                                        )
                                    }
                                    <span>Candidate</span>
                                </>
                            )}
                            {...sortProps}
                        />
                        <TableCellHead className={classes.tableCellHead} label="Email" hideSort />
                        <TableCellHead className={classes.tableCellHead} label="Status" {...sortProps} />
                        <TableCellHead className={classes.tableCellHead} label="Source" hideSort />
                        <TableCellHead className={classes.tableCellHead} label=" " hiealign="right" hideSort />
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {
                        (isLoading || isLoadingLocal) && (
                            <div className={classes.loaderWrapper}>
                                <CircularProgress color="primary" />
                            </div>
                        )
                    }
                    {
                        Object
                            .keys(allCandidates)
                            .map(key => (
                                <CandidateRow
                                    key={allCandidates[key].candidateId}
                                    companyTags={companyTags}
                                    candidate={allCandidates[key]}
                                />
                            ))
                    }
                </TableBody>
            </Table>
            <Pagination
                currentPage={page}
                totalPages={totalPagesCount}
                handleChangePage={handleChangePage}
            />
        </TableContainer>
    );
});

export default CandidatesTable;
