import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import PreviewMessageDialog from 'components/dialogs/PreviewMessageDialog';

import CircularProgress from '@mui/material/CircularProgress';
import {
    changeReminderEmailData,
    deleteReminderEmailData, getAssessmentAdmins,
    getAssessmentReminderEmailData
} from 'requests/EmailRequests';
import styles from './styles';


const PreviewReminderEmailButton = observer(({ classes, audition }) => {
    const [emailDefaultData, setEmailDefaultData] = useState({});
    const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
    const [replyToOptions, setReplyToOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { slug, brand } = audition;

    useEffect(() => {
        if (!slug) return;
        if (!openPreviewEmail) {
            setEmailDefaultData({});
            return;
        }
        setLoading(true);
        getAssessmentReminderEmailData(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setData(data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        getAssessmentAdmins(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setReplyToOptions(data);
                }
            });
    }, [openPreviewEmail, slug]);

    const saveSettings = data => changeReminderEmailData(slug, { ...emailDefaultData, ...data })
        .then(({ success, data: newData }) => {
            if (success && newData) {
                setData(newData);
            }
            return { success, errorForm: {} };
        });

    const setData = (data) => {
        setEmailDefaultData({ ...data, replyTo: data.replyTo && data.replyTo.id });
    };

    const handleRejectionPreview = () => {
        setOpenPreviewEmail(!openPreviewEmail);
    };

    const handleChangeSettingsParam = (newValue) => {
        setEmailDefaultData({ ...emailDefaultData, ...newValue });
    };

    const resetSettings = () => deleteReminderEmailData(slug)
        .then(() => {
            setEmailDefaultData({});
        });

    return (
        <>
            <Button
                color="primary"
                disabled={loading}
                onClick={handleRejectionPreview}
                className={classes.actionButton}
            >
                {
                    loading ? (
                        <CircularProgress size={20} />
                    ) : <span>Preview and edit reminder email</span>
                }
            </Button>
            {
                !loading && (
                    <PreviewMessageDialog
                        {...emailDefaultData}
                        open={openPreviewEmail}
                        type="reminder"
                        isFirstProgressColumn
                        replyToOptions={replyToOptions}
                        opportunity={{
                            uuid: audition && audition.uuid,
                            jobTitle: audition && audition.name
                        }}
                        columnType="progress"
                        handleClose={handleRejectionPreview}
                        handleChangeSettingsParam={handleChangeSettingsParam}
                        onSave={saveSettings}
                        onReset={resetSettings}
                        assessmentBrand={brand}
                    />
                )
            }
        </>
    );
});

export default withStyles(styles)(PreviewReminderEmailButton);
