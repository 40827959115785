import React from 'react';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';

import styles from './styles';

const RemoveMemberDialog = ({ open, classes, user: { fullName, invited, email }, onRemove, onClose, removing }) => {
    const titleComponent = invited ? 'Cancel Invitation' : 'Remove Member';
    const removeLabelButton = invited ? 'Cancel Invitation' : 'Remove';
    const dialogContent = invited ? (
        <>
            <p>Are you sure you want to cancel the invitation for <b>{email}</b>?</p>
            <p className="u-txt--14">By cancelling this invitation they will be unable to login to Vervoe from the email. This action can’t be undone.</p>
        </>
    ) : (
        <>
            <p>Are you sure you want to remove <b>{fullName}</b>?</p>
            <p className="u-txt--14">By removing this member you will be removing their access to this account. This action can’t be undone.</p>
        </>
    );

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            color="primary"
            onClose={onClose}
            handleClose={onClose}
            titleComponent={titleComponent}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        onClick={onClose}
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onRemove}
                        disabled={removing}
                        className={classes.sendButton}
                    >
                        {
                            removing ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : <span>{removeLabelButton}</span>
                        }
                    </Button>
                </>
            )}
        >
            {dialogContent}
        </Dialog>
    );
};

export default withStyles(styles)(RemoveMemberDialog);
