import React from 'react';
import { withStyles, withTheme } from '@mui/styles';

import {
    TEXT, MULTIPLE_CHOICE_AUTOGRADED,
    SINGLE_CHOICE_AUTO_GRADED,
    AUDIO, VIDEO, NO_ANSWER,
    ANY_FILE, GOOGLE_DOC, SPREADSHEET,
    PRESENTATION, CODE_TEST, CODE_TEST_AUTOGRADED,
    SPREADSHEET_AUTO_GRADED, NUMBER, FEEDBACK,
    CS_SIMULATOR, TYPING_TEST, CYBER_SIMULATOR, EXCEL_AUTO_GRADED,
    SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR
} from 'helper/constants';

import TextAnswer from '../TextAnswer';
import FileAnswer from './components/FileAnswer';
import NoAnswer from './components/NoAnswer';
import ImmersiveAnswer from './components/ImmersiveAnswer';
import MultiChoice from './components/MultiChoice';
import Recorder from './components/Recorder';
import CodeEditorQuestion from '../CodeEditorQuestion';
import CodeEditorAutograded from './components/CodeEditorAutograded';
import NumberAnswer from './components/NumberAnswer';
import NoInteractionTooltip from './components/NoInteractionTooltip';
import Simulator from './components/Simulator';
import TypingTest from './components/TypingTest';

const styles = {
    wrapper: {
        cursor: 'pointer'
    }
};

const AnswerTypes = ({
    data, index, classes, hideTooltip, isPreviewPage,
    interview, theme, brandColor = theme.palette.primary.main,
    isOrderedMultipleChoice
}) => {
    const {
        uuid, answerType,
        answers, validAnswers,
        codeChallengeQuestions
    } = data;

    const noInteractionTooltipAnswer = [GOOGLE_DOC,
        SPREADSHEET, PRESENTATION, SPREADSHEET_AUTO_GRADED,
        MULTIPLE_CHOICE_AUTOGRADED, SINGLE_CHOICE_AUTO_GRADED,
        CS_SIMULATOR, CYBER_SIMULATOR, EXCEL_AUTO_GRADED,
        SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR
    ].includes(answerType);

    return (
        <>
            <div
                data-tip
                data-for={`non_interactive_preview_${uuid}`}
                className={classes.wrapper}
            >
                {
                    {
                        [TEXT]: <TextAnswer />,
                        [AUDIO]: <Recorder />,
                        [VIDEO]: <Recorder video />,
                        [MULTIPLE_CHOICE_AUTOGRADED]: <MultiChoice {...{ question: data, answers, validAnswers, checkbox: true, hideTooltip, brandColor, isOrderedMultipleChoice }} />,
                        [SINGLE_CHOICE_AUTO_GRADED]: <MultiChoice {...{ question: data, answers, validAnswers, hideTooltip, brandColor, isOrderedMultipleChoice }} />,
                        [NO_ANSWER]: <NoAnswer />,
                        [ANY_FILE]: <FileAnswer />,
                        [GOOGLE_DOC]: <ImmersiveAnswer {...{ question: data, questionNumber: index, hideTooltip }} />,
                        [SPREADSHEET]: <ImmersiveAnswer {...{ question: data, questionNumber: index, hideTooltip }} />,
                        [PRESENTATION]: <ImmersiveAnswer {...{ question: data, questionNumber: index, hideTooltip }} />,
                        [SPREADSHEET_AUTO_GRADED]: <ImmersiveAnswer {...{ question: data, questionNumber: index, hideTooltip }} />,
                        [CODE_TEST]: <CodeEditorQuestion data="" />,
                        [CODE_TEST_AUTOGRADED]: <CodeEditorAutograded data={codeChallengeQuestions} />,
                        [NUMBER]: <NumberAnswer />,
                        [FEEDBACK]: <TextAnswer />,
                        [TYPING_TEST]: <TypingTest />,
                        [CS_SIMULATOR]: <Simulator {... { question: data, interview, isPreviewPage }} />,
                        [CYBER_SIMULATOR]: <Simulator {... { question: data, interview, isPreviewPage }} />,
                        [EXCEL_AUTO_GRADED]: <ImmersiveAnswer {...{ question: data, questionNumber: index, hideTooltip, previewLink: data?.oneDriveFile?.previewUrl }} />,
                        [SALES_SIMULATOR]: <Simulator {... { question: data, interview, isPreviewPage }} />,
                        [QA_SIMULATOR]: <Simulator {... { question: data, interview, isPreviewPage }} />,
                        [MARKETING_SIMULATOR]: <Simulator {... { question: data, interview, isPreviewPage }} />
                    }[answerType] || null
                }
            </div>
            { !noInteractionTooltipAnswer && !hideTooltip && <NoInteractionTooltip id={`non_interactive_preview_${uuid}`} /> }
        </>
    );
};

export default withTheme(withStyles(styles)(AnswerTypes));
