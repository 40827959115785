import React, { useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import { removeSkill } from 'requests/CMSRequests';
import { observer } from 'mobx-react-lite';
import { appCtx } from 'components/appStore';
import { FilterCtx } from 'pages/expert_assessments/filter-store';


const RemoveSkillDialog = observer(({ open, onRemove, skill, onClose }) => {
    const [loading, setLoading] = useState(false);
    const { removeFilterPropertyFromOutside } = useContext(FilterCtx);

    const { flashMessage } = useContext(appCtx);

    const submit = () => {
        setLoading(true);
        removeSkill(skill.id)
            .then(() => {
                removeFilterPropertyFromOutside('skills', skill.title);
                onClose();
                flashMessage('Skill removed');
                if (onRemove) onRemove(skill.id);
            })
            .catch((e) => {
                if (e.response.data && e.response.data.msg) {
                    flashMessage(e.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            color="primary"
            onClose={onClose}
            handleClose={onClose}
            titleComponent="Are you sure you want to remove this skill?"
            actionComponent={(
                <>
                    <Button
                        className="u-txt--bold"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className="u-pdn--lx3 u-pdn--rx3"
                        onClick={submit}
                        disabled={loading}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                />
                            ) : <span>Remove</span>
                        }
                    </Button>
                </>
            )}
        >
            Skill will be removed from Question Library
        </Dialog>
    );
});

export default RemoveSkillDialog;
