import React from 'react';
import { withStyles } from '@mui/styles';

import ContentWrapper from 'components/layout/PageWrapper/ContentWrapper';
import { round } from 'helper/commonFunctions';
import { observer } from 'mobx-react-lite';
import Table from 'components/table_components/table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCellHead from 'components/table_components/table_cell_head';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCellCustom from 'components/table_components/table_cell';
import Button from '@mui/material/Button';
import Moment from 'react-moment';

const styles = theme => ({
    cellHead: {
        '&:first-child, &:last-child': {
            paddingLeft: 16
        }
    },
    header: {
        fontSize: 18,
        marginBottom: 25,
        fontWeight: 700,
        color: theme.palette.grey[900]
    }
});

const CandidatePackListBlock = observer(({
    classes, companyCompletePackages
}) => {
    if (!companyCompletePackages || !companyCompletePackages.length) return null;

    return (
        <ContentWrapper className="u-pdn--bx6">
            <header className={classes.header}>Candidate Pack</header>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellHead label="Date" className={classes.cellHead} hideSort />
                            <TableCellHead label="Purchased Completions" hideSort />
                            <TableCellHead label="Remaining Completions" className={classes.cellHead} hideSort />
                            <TableCellHead label="Expiry" hideSort />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            companyCompletePackages.map((companyCompletePackage) => {
                                const { id, limit, createdAt, expiredAt, userAuditionsLeft } = companyCompletePackage;
                                return (
                                    <TableRow key={id}>
                                        <TableCellCustom align="left">
                                            <Moment format="D MMM, YYYY">{createdAt}</Moment>
                                        </TableCellCustom>
                                        <TableCellCustom align="left">
                                            {limit}
                                        </TableCellCustom>
                                        <TableCellCustom align="left">
                                            {userAuditionsLeft}
                                        </TableCellCustom>
                                        <TableCellCustom align="left">
                                            {
                                                expiredAt ? (
                                                    <Moment format="D MMM, YYYY">{expiredAt}</Moment>
                                                ) : <span>-</span>
                                            }
                                        </TableCellCustom>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </ContentWrapper>
    );
});

export default withStyles(styles)(CandidatePackListBlock);
