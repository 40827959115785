import React, { useState, useEffect } from 'react';
import { CS_SIMULATOR, CYBER_SIMULATOR, QA_SIMULATOR, SALES_SIMULATOR, MARKETING_SIMULATOR } from 'helper/constants';
import { withStyles } from '@mui/styles';
import nl2br from 'react-nl2br';
import Zendesk from 'helper/zendeskFunctions';
import SimulationBanner from '../simulation_components/SimulationBanner';
import CSSimulatorDialog from './SimulatorDialog';
import VideoSimulatorDialog from './VideoSimulatorDialog';

import styles from './styles';

const Simulator = ({ classes, question, interview, isPreviewPage }) => {
    const [open, setOpen] = useState(false);
    const { simulationDescription, simulationCandidateQuestionInstructions, answerType } = question;

    useEffect(() => () => {
        Zendesk.show();
    }, []);

    useEffect(() => {
        if (open) {
            Zendesk.hide();
        } else {
            Zendesk.show();
        }
    }, [open]);

    const handleDialog = () => {
        setOpen(!open);
    };

    const returnOnClick = () => {
        switch (answerType) {
            case CYBER_SIMULATOR:
            case CS_SIMULATOR:
            case SALES_SIMULATOR:
            case QA_SIMULATOR:
            case MARKETING_SIMULATOR:
                return handleDialog;
            default:
                return undefined;
        }
    };

    const commonDialogProps = { question, open, onClose: handleDialog };

    const returnContent = () => {
        switch (answerType) {
            case CS_SIMULATOR:
                return (
                    <CSSimulatorDialog
                        {...commonDialogProps}
                        interview={interview}
                    />
                );
            case CYBER_SIMULATOR:
                return (
                    <VideoSimulatorDialog
                        {...commonDialogProps}
                        link="https://player.vimeo.com/video/798956481?h=d8b0bb1d19&title=0&byline=0&portrait=0"
                    />
                );
            case SALES_SIMULATOR:
                return (
                    <VideoSimulatorDialog
                        {...commonDialogProps}
                        link="https://player.vimeo.com/video/825307900?h=cde9767333"
                    />
                );
            case MARKETING_SIMULATOR:
                return (
                    <VideoSimulatorDialog
                        {...commonDialogProps}
                        link="https://player.vimeo.com/video/860756476?h=d8b0bb1d19&title=0&byline=0&portrait=0"
                    />
                );
            case QA_SIMULATOR:
                return (
                    <VideoSimulatorDialog
                        {...commonDialogProps}
                        link="https://player.vimeo.com/video/850414794?h=d8b0bb1d19"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className={classes.wrapper}>
            {
                !isPreviewPage && (
                    <p className={simulationCandidateQuestionInstructions && classes.divider}>
                        {
                            ([SALES_SIMULATOR, QA_SIMULATOR].includes(answerType) && simulationDescription) ? (
                                <div dangerouslySetInnerHTML={{ __html: simulationDescription.toString('html') }} />
                            ) : <span>{nl2br(simulationDescription)}</span>
                        }
                    </p>
                )
            }
            <p>{nl2br(simulationCandidateQuestionInstructions)}</p>
            <SimulationBanner
                type={answerType}
                onClick={returnOnClick()}
            />
            {returnContent()}
        </div>
    );
};


export default withStyles(styles)(Simulator);
