import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextInputOutlined from 'libraries/TextInput';
import Button from '@mui/material/Button';
import GradeScale from 'components/grade_components/GradeScale';

import { getPostHiringSurveyData, sendPostHiringSurveyData } from 'requests/CandidatesRequests';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import HiredEvents from 'events/HiredEvents';
import PoweredBy from 'img/poweredBy';
import Tooltip from 'libraries/Tooltip';
import InfoIcon from 'img/icons/icon-info.svg';
import FeedbackConfirmed from './FeedbackConfirmed';
import ExpiredLink from './ExpiredLink';

import useStyles from './styles';

const PostHiringSurvey = ({ location, history }) => {
    const [grade, setGrade] = useState(null);
    const [comments, setComments] = useState('');
    const [hireAgain, setHireAgain] = useState('');
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [screenToShow, setScreenToShow] = useState('');
    const [eventSent, setEventSent] = useState(false);

    const classes = useStyles({ companyColor: userData ? userData.companyColor : '#fff' });

    const parsed = qs.parse(location.search);
    const { token } = parsed;

    useEffect(() => {
        initialize();
    }, [token]);

    const initialize = () => {
        setLoading(true);
        getPostHiringSurveyData(token)
            .then(({ data, success }) => {
                if (data && success) {
                    const { canEdit, nextLink } = data;
                    if (!canEdit && !nextLink) {
                        setUserData(data);
                        setScreenToShow('sent');
                    } else if (!canEdit && nextLink) {
                        window.location = nextLink;
                    } else {
                        setUserData(data);
                        HiredEvents.POST_HIRE_SURVEY_OPENED(data.eventProperties);
                    }
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 418) {
                    setScreenToShow('expired');
                } else {
                    history.push('/forbidden');
                }
            })
            .finally(() => setLoading(false));
    };


    const sendFeedback = () => {
        setLoading(true);
        sendPostHiringSurveyData(token, hireAgain, grade, comments)
            .then(() => {
                if (userData.nextLink) {
                    window.location = userData.nextLink;
                } else {
                    setScreenToShow('sent');
                }
            })
            .finally(() => setLoading(false));
    };

    const today = moment(new Date()).format('MMM D yyyy');

    const handleRadioChange = (e) => {
        setHireAgain(e.target.value);
        setComments('');
        sendEventIfNotSent();
    };

    const sendEventIfNotSent = () => {
        if (!eventSent) {
            HiredEvents.POST_HIRE_SURVEY_STARTED(userData?.eventProperties);
            setEventSent(true);
        }
    };

    const handleChangeGrade = (value) => {
        setGrade(value);
        sendEventIfNotSent();
    };

    const returnCandidateName = () => {
        if (!userData) return null;
        const { fullName } = userData.candidate;
        const lastSymbol = fullName.slice(-1);
        return `${fullName}'${lastSymbol === 's' ? '' : 's'}`;
    };

    if (screenToShow === 'expired') return <ExpiredLink />;

    if (!userData) return null;

    return (
        <Container className={classes.container}>
            <div>
                <div
                    role="presentation"
                    className={classes.logoContainer}
                >
                    { userData?.companyLogo && userData?.companyLogo.length
                        ? (
                            <img
                                style={{ maxWidth: 200, maxHeight: 100 }}
                                alt="logo"
                                src={userData.companyLogo[0]}
                            />
                        ) : (
                            <div className={classes.header}>
                                {userData?.eventProperties.companyName}
                            </div>
                        )
                    }
                </div>
                {screenToShow === 'sent' ? <FeedbackConfirmed /> : (
                    <div className={classes.wrapper}>
                        <div className={classes.section}>
                            <div className={classes.date}>
                                {today}
                            </div>
                            <div>
                                <p>Hi <b>{userData?.hiringManager.fullName}</b>,</p>
                                <p>It’s time to review <b>{returnCandidateName()}</b> performance.</p>
                                <p>This information is confidential and will be used for reporting and insights within the skills assessment tool Vervoe. Your feedback will not be shared directly with <b>{userData?.candidate.firstName}</b>.</p>
                                <p>Please take a moment to provide your honest feedback.</p>
                            </div>
                        </div>
                        <div className={classes.section}>
                            <div>Knowing everything you know now would you hire this person again?</div>

                            <RadioGroup
                                className={classes.radio}
                                aria-label="Hire"
                                name="hire"
                                value={hireAgain}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio name="yes" />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio name="no" />}
                                    label="No"
                                />
                                <FormControlLabel
                                    value="other"
                                    control={<Radio name="other" />}
                                    label="Other"
                                />
                            </RadioGroup>
                            <div className="u-mrg--tx3">
                                <div>Please provide reason for selecting other.</div>
                                <TextInputOutlined
                                    hasCounter
                                    multiline
                                    name="comments"
                                    label="Comments"
                                    variant="outlined"
                                    maxLength={500}
                                    onChange={e => setComments(e.target.value)}
                                    onClick={sendEventIfNotSent}
                                    className={classes.input}
                                    value={comments}
                                />
                            </div>
                        </div>
                        <div className={classes.section}>
                            <div>How would you rate this person’s performance overall?
                                <Tooltip label={<div>0 = Underperforming hire <br /> 10 = Star performer </div>}>
                                    <img className="u-mrg--lx2" src={InfoIcon} alt="" />
                                </Tooltip>
                            </div>
                            <div className={classes.gradeScaleWrapper}>
                                <GradeScale
                                    postHiring
                                    canGrade
                                    willBeGraded
                                    saveGrade={handleChangeGrade}
                                    selectedColor={userData?.companyColor}
                                    sections={['Unsatisfactory', 'Average', 'Exceptional']}
                                />
                            </div>
                            <Button
                                className={classes.button}
                                style={{ backgroundColor: userData?.companyColor }}
                                color="primary"
                                variant="contained"
                                onClick={sendFeedback}
                                disabled={loading || ((hireAgain === 'other' || hireAgain === 'no') && !comments?.trim())}
                            >
                                {
                                    loading ? (
                                        <CircularProgress
                                            size={22}
                                            className={classes.loader}
                                        />
                                    ) : <span>Submit</span>
                                }
                            </Button>
                        </div>
                    </div>
                )}
                <div className={classes.imgContainer}>
                    <PoweredBy color={userData?.companyColor} />
                </div>
            </div>
        </Container>
    );
};

export default withRouter(PostHiringSurvey);
