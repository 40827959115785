import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import AssessmentEvents from 'events/AssessmentEvents';

import { getInsightsMultipleChoiceResponse, exportMultipleChoiceResponceInsights } from 'requests/AssessmentRequests';
import QuestionTooltipBlack from 'components/tooltips/QuestionTooltipBlack';
import Table from 'components/table_components/table';
import TableCellHead from 'components/table_components/table_cell_head';
import DownloadCsv from 'components/insight_components/download_csv';

import InsightsSectionWrapper from '../../../../components/insight_components/insight_section_wrapper';
import QuestionDataImg from '../QuestionData.svg';
import QuestionTableRow from './QuestionTableRow';


const styles = theme => ({
    table: {
        marginBottom: 15
    },
    tableContainer: {
        marginTop: 20,
        position: 'relative',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    tableCellHead: {
        height: 54,
        padding: 16,
        whiteSpace: 'nowrap',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[300]
    },
    tableCellHeadQuestion: {
        width: 140,
        paddingLeft: '20px !important'
    },
    tableHead: {
        border: `1px solid ${theme.palette.grey[300]}`
    },
    tableCellHeadAnswered: {
        minWidth: 350
    }
});

const QuestionByQuestion = observer(({ classes, audition }) => {
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [isRandomized, setIsRandomized] = useState(false);
    const [hasCandidateAnswers, setHasCandidateAnswers] = useState(false);
    const { name: ttName, uuid: ttId, slug } = audition;


    useEffect(() => {
        setLoading(true);
        getInsightsMultipleChoiceResponse(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    const { questions, wasRandomized, hasCandidateAnswers: newHasCandidateAnswers } = data;
                    setChartData(questions);
                    setIsRandomized(wasRandomized);
                    setHasCandidateAnswers(newHasCandidateAnswers);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (!chartData?.length) return null;

    return (
        <InsightsSectionWrapper
            header={(
                <Typography variant="h5" className="u-txt--left">
                    Multiple Choice Response Insights
                </Typography>
            )}
            imgSrc={QuestionDataImg}
            loading={loading}
            isEmpty={!chartData || !chartData.length || !hasCandidateAnswers || isRandomized}
            customEmptyLabel={isRandomized ? 'Questions and groups have been randomized' : ''}
            audition={audition}
            emptyLabel="question"
        >
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCellHead
                                label="Question"
                                hideSort
                                className={clsx(classes.tableCellHead, classes.tableCellHeadQuestion)}
                            />
                            <TableCellHead
                                label="Skill"
                                className={clsx(classes.tableCellHead)}
                                sx={{ pl: '10px !important' }}
                                hideSort
                            />
                            <TableCellHead
                                className={clsx(classes.tableCellHead, classes.tableCellHeadAnswered, 'u-pdn--lx6')}
                                labelComponent={(
                                    <div className="u-dsp--f u-ai--center u-jc--end">
                                        Candidate Answers
                                        <QuestionTooltipBlack
                                            className="u-pdn--tx2 u-pdn--bx2"
                                            label={<>View the distribution of answers from your multiple choice options here so you can optimise the difficulty.</>}
                                        />
                                    </div>
                                )}
                                label="Answered"
                                align="right"
                                hideSort
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {
                            chartData?.map((item) => {
                                const { question, answers, ...other } = item;
                                const { id: questionId } = question;
                                return (
                                    <QuestionTableRow
                                        key={questionId}
                                        question={question}
                                        audition={audition}
                                        answers={answers}
                                        {...other}
                                    />
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <DownloadCsv
                request={() => exportMultipleChoiceResponceInsights(slug)}
                enabled={Boolean(chartData) && !isRandomized}
                filename={`${audition.name}-multichoice-responses-${new Date().toISOString().slice(0, 10)}.csv`}
                onExport={() => {
                    AssessmentEvents.INSIGHTS_MULTICHOICE_DOWNLOADED({ ttId, ttName });
                }}
            />
        </InsightsSectionWrapper>
    );
});

export default withStyles(styles)(withRouter(QuestionByQuestion));
