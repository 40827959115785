export default theme => ({
    listWrapper: {
        padding: 0
    },
    listItem: {
        listStyle: 'none',
        fontSize: 14,
        display: 'flex',
        alignItems: 'flex-start',
        color: theme.palette.grey[900],
        fontWeight: 600,
        marginBottom: 25,
        marginTop: 5,
        '& span': {
            display: 'inline-block',
            border: `2px solid ${theme.palette.primary.main}`,
            width: 16,
            minWidth: 16,
            maxWidth: 16,
            flexBasis: 16,
            height: 16,
            marginTop: 2,
            marginRight: 10
        },
        '@media only screen and (max-width: 767px)': {
            marginBottom: 30
        }
    },
    label: {
        maxWidth: 720,
        wordBreak: 'break-word'
    },
    optionsWrapper: {
        minWidth: 550,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            minWidth: 'auto'
        }
    },
    wrapper: {
        margin: '0 auto',
        width: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 550
        }
    }
});
