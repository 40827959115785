import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TableCellCustom from 'components/table_components/table_cell';
import QuestionNumberLabel from 'components/candidate_cards/QuestionNumberLabel';
import QuestionSkillLabel from 'components/candidate_cards/QuestionSkillLabel';

import QuestionTypes from 'components/marketplace/Preview/common/QuestionTypes';
import AnswerTypes from 'components/marketplace/Preview/common/AnswerTypes';

import useStyles from './styles';


const QuestionTableRow = observer(({ question, audition, answers }) => {
    const [expanded, setExpanded] = useState(false);
    const { questionGroup, sort } = question;
    const {
        tableRow, tableCell, answerCount,
        tableCellProgress, skillName,
        accordionRow, expandedRow,
        expandedIcon, icon
    } = useStyles();

    const handleRowClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <TableRow className={tableRow} onClick={handleRowClick}>
                <TableCellCustom
                    align="left"
                    className={clsx(tableCell, 'u-txt--bold')}
                    sx={{ pr: '10px !important' }}
                >
                    <QuestionNumberLabel
                        question={question}
                        questionNumber={sort}
                    />
                </TableCellCustom>
                <TableCellCustom align="left" className={tableCell} sx={{ pl: '10px !important' }}>
                    <div className="u-dsp--f u-ai--center u-jc--start">
                        {
                            questionGroup ? (
                                <QuestionSkillLabel
                                    skill={questionGroup.title}
                                    className={skillName}
                                />
                            ) : <span>-</span>
                        }
                    </div>
                </TableCellCustom>
                <TableCellCustom align="right" className={tableCell}>
                    <ExpandMoreIcon className={clsx(icon, expanded && expandedIcon)} />
                </TableCellCustom>
            </TableRow>
            <TableRow className={clsx(accordionRow, expanded && expandedRow)}>
                {
                    expanded && (
                        <>
                            <TableCellCustom
                                align="left"
                                colSpan={2}
                                className={clsx(tableCell)}
                            >
                                <div style={{ marginTop: '-13px' }}>
                                    <QuestionTypes data={question} className="u-mrg--tx2" />
                                    <AnswerTypes
                                        interview={audition}
                                        data={question}
                                        index={sort - 1}
                                        isPreviewPage
                                        isOrderedMultipleChoice
                                    />
                                </div>
                            </TableCellCustom>
                            <TableCellCustom className={clsx(tableCell, tableCellProgress)}>
                                <div>
                                    {
                                        answers.map(({ id, count, percentage }, index) => (
                                            <div key={id} className={answerCount}>
                                                {index + 1}: {count || '-'}<br />
                                                {
                                                    Boolean(count) && (
                                                        <>({percentage}%)</>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </TableCellCustom>
                        </>
                    )
                }
            </TableRow>
        </>
    );
});

export default withRouter(QuestionTableRow);
