import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import { observer } from 'mobx-react-lite';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { appCtx } from 'components/appStore';
import {
    getSmartRecruitersV2Params,
    loginSmartRecruitersV2,
    updateSmartRecruitersV2,
    resetSmartRecruitersV2
} from 'requests/SubscriptionRequests';
import Switch from 'libraries/SwitchBrandWithLabel';
import TextInput from 'libraries/TextInput';
import SettingsEvents from 'events/SettingsEvents';
import AssessmentsIcon from './siutcase.svg';
import CandidateIcon from './candidatePlus.svg';
import CandidatesIcon from './candidates.svg';
import SmartRecruitersLogo from './smartRecruitersColorLogo.svg';
import IntegrationsAuthWrapper from '../../../IntegrationsAuthWrapper';

const styles = theme => ({
    heading: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '28px',
        marginBottom: 15
    },
    input: {
        maxWidth: 500,
        marginBottom: 10,
        marginTop: '0!important'
    },
    link: {
        color: theme.palette.blue[500],
        fontWeight: 700
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 0',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        fontWeight: 700
    },
    resetText: {
        color: theme.palette.red.primary,
        fontWeight: 600,
        margin: '20px 0'
    },
    loader: {
        color: theme.palette.blue.main
    }
});

const permissionsList = [{
    text: 'Open Assessments List',
    icon: AssessmentsIcon
}, {
    text: 'Invited candidates',
    icon: CandidateIcon
}, {
    text: 'Candidates results',
    icon: CandidatesIcon
}];


const SmartRecruitersV2Content = observer(({ classes, location, isIntegrationsList = false }) => {
    const { flashMessage } = useContext(appCtx);
    const [loading, setLoading] = useState(false);
    const [activated, setActivated] = useState(false);
    const [sendCandidateIntegrationLink, setSendCandidateIntegrationLink] = useState(false);
    const [authorizedToSR, setAuthorizedToSR] = useState(false);
    const [SRCompanyId, setSRCompanyId] = useState(null);

    useEffect(() => {
        getSmartRecruitersV2Obj();
    }, [location]);

    const { companyIdSR, redirectSR } = qs.parse(location.search);

    const getSmartRecruitersV2Obj = () => {
        setLoading(true);
        getSmartRecruitersV2Params()
            .then(({ data }) => {
                if (!data) return;
                const {
                    enabled, authorized, partnerCompanyId,
                    sendCandidateIntegrationLink: newSendCandidateIntegrationLink
                } = data;
                setActivated(enabled);
                setSendCandidateIntegrationLink(newSendCandidateIntegrationLink);
                setAuthorizedToSR(authorized);
                setSRCompanyId(partnerCompanyId);
                if (!data) {
                    updateSR({ enabled: !activated });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateSR = (newData) => {
        updateSmartRecruitersV2(newData)
            .then(({ success, data }) => {
                if (success && data) {
                    if (!data) return;
                    const {
                        enabled, authorized, partnerCompanyId,
                        sendCandidateIntegrationLink: newSendCandidateIntegrationLink
                    } = data;
                    setActivated(enabled);
                    setAuthorizedToSR(authorized);
                    setSRCompanyId(partnerCompanyId);
                    setSendCandidateIntegrationLink(newSendCandidateIntegrationLink);
                    flashMessage('Integration settings updated', 'done');
                    SettingsEvents.SMARTRECRUITERS_V2_ENABLED({ enabled });
                }
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            });
    };

    const loginToSR = () => {
        setLoading(true);
        loginSmartRecruitersV2(companyIdSR)
            .then(() => {
                window.location = redirectSR;
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
                setLoading(false);
            });
    };

    const resetIntegration = () => {
        setLoading(true);
        resetSmartRecruitersV2()
            .then(() => {
                setAuthorizedToSR(false);
                setSRCompanyId(null);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const content = <>
        <h3 className={classes.heading}>Connect Vervoe to SmartRecruiters v2</h3>
        <Switch
            label="Enable SmartRecruiters Integration"
            checked={activated}
            value={activated}
            onChange={() => updateSR({ enabled: !activated })}
        /><br />
        {activated && (
            <>
                <p>
                    To set up SmartRecruiters v2, please use the&nbsp;
                    <a
                        href="https://www.smartrecruiters.com/app/home/"
                        className={classes.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        link
                    </a>
                    .
                </p>
                <Switch
                    className="u-mrg--bx6"
                    label="Send Candidate Integration Link"
                    checked={sendCandidateIntegrationLink}
                    value={sendCandidateIntegrationLink}
                    onChange={() => updateSR({ sendCandidateIntegrationLink: !sendCandidateIntegrationLink })}
                /><br />
            </>
        )}
        {((authorizedToSR && isIntegrationsList) || !isIntegrationsList) && <>
            <TextInput
                label="Company ID"
                fullWidth
                className={classes.input}
                type="text"
                variant="outlined"
                value={companyIdSR || SRCompanyId}
                disabled
            />
            <div className="u-mrg--tx3">
                <Button
                    variant="contained"
                    color="primary"
                    disabled
                >
                    {authorizedToSR ? 'Authorized' : 'Not authorized'}
                </Button>
                {authorizedToSR && (
                    <Button
                        className="u-mrg--lx3"
                        color="primary"
                        onClick={resetIntegration}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress
                                className={classes.loader}
                                size={24}
                                show
                                thickness={2}
                            />
                        ) : <span>Reset integration</span>}
                    </Button>
                )}
            </div>
        </>}
        {authorizedToSR && companyIdSR !== SRCompanyId && !loading && !isIntegrationsList && (
            <p className={classes.resetText}>
                Please reset in order to set the integration.
            </p>
        )}
        {isIntegrationsList && authorizedToSR && (
            <img
                src={SmartRecruitersLogo}
                alt="SmartRecruiters"
                className="u-mrg--bx2 u-mrg--tx5"
            />
        )}
        {((isIntegrationsList && authorizedToSR) || !isIntegrationsList) && <>
            <p className="u-mrg--bx1 u-mrg--tx4 u-txt--bold">
                SmartRecruiters integration will use the above information to:
            </p>
            {permissionsList.map(({ icon, text }) => (
                <div className={classes.option}>
                    <img className="u-mrg--rx3" src={icon} alt="*" />
                    {text}
                </div>
            ))}
        </>}
        {!isIntegrationsList && (
            <div className="u-dsp--f u-jc--end u-mrg--tx4">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={loginToSR}
                    disabled={authorizedToSR || !activated || loading}
                >
                    {loading ? (
                        <CircularProgress
                            size={24}
                            show
                            thickness={2}
                        />
                    ) : <span>Allow and Continue</span>}
                </Button>
            </div>
        )}
    </>;

    if (isIntegrationsList) return content;

    return (
        <IntegrationsAuthWrapper>
            {content}
        </IntegrationsAuthWrapper>
    );
});

export default withRouter(withStyles(styles)(SmartRecruitersV2Content));
