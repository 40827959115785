import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import { getCompanyTags } from 'requests/SettingsRequests';
import CircularProgress from '@mui/material/CircularProgress';
import { clone } from 'helper/commonFunctions';
import CreateButton from './CreateButton';
import TagItem from './TagItem';


const CandidatesTags = () => {
    const [companyTags, setCompanyTags] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getCompanyTags()
            .then(({ success, data }) => {
                if (success && data) {
                    setCompanyTags(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const addNewTag = (tag) => {
        const newCompanyTags = clone(companyTags);
        newCompanyTags.push(tag);
        setCompanyTags(newCompanyTags);
    };

    const removeTag = (tagIndex) => {
        const newCompanyTags = clone(companyTags);
        newCompanyTags.splice(tagIndex, 1);
        setCompanyTags(newCompanyTags);
    };

    const renameTag = (tagIndex, value) => {
        const newCompanyTags = clone(companyTags);
        newCompanyTags[tagIndex].title = value;
        setCompanyTags(newCompanyTags);
    };

    return (
        <div>
            <Typography variant="h6" className="u-mrg--bx2">
                Candidate Tags
                <InfoTooltip
                    className="u-txt--12 u-txt--normal"
                    isBlack
                    text={<>Create, edit and delete candidate <br />tags for use across all assessments</>}
                />
            </Typography>
            <div>
                {
                    isLoading ? (
                        <CircularProgress size={25} color="primary" />
                    ) : (
                        <>
                            {
                                companyTags && companyTags.length ? (
                                    companyTags.map((tag, index) => (
                                        <TagItem
                                            tag={tag}
                                            key={tag.id}
                                            removeTag={() => removeTag(index)}
                                            renameTag={value => renameTag(index, value)}
                                            tagIndex={index}
                                        />
                                    ))
                                ) : <span>No candidate tags.</span>
                            }
                        </>
                    )
                }
                <CreateButton
                    addNewTag={addNewTag}
                    tagsLength={companyTags.length}
                />
            </div>
        </div>
    );
};

export default CandidatesTags;
