import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import Dialog from 'libraries/Dialog';

import { addHiringManager, assignHiringManagerOrDate } from 'requests/CandidatesRequests';

import { hiredCandidateCtx } from 'pages/candidates/HiredCandidates/hiredCandidateStore';
import HiringManagerContent from 'components/dialogs/AssignHiringManagerDialog/HiringManagerContent';
import { appCtx } from 'components/appStore';
import HiredEvents from 'events/HiredEvents';
import DateSelectionContent from './DateSelectionContent';

import FinishDialog from './FinishDialog';
import styles from './styles';


const HiringFlowDialog = observer(({
    classes, open, onClose, candidates,
    updateUserFields, assessment, onSuccessClose, callback
}) => {
    const [hiringManagerFullName, setFullName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updatedFields, setUpdatedFields] = useState({
        hiringManager: candidates.length === 1 && candidates[0].hiringManager ? candidates[0].hiringManager : {},
        hiredDate: candidates.length === 1 && candidates[0].hiredDate ? candidates[0].hiredDate : null
    });
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const { flashMessage } = useContext(appCtx);
    const { loadHiredCandidates, fetchFiltersForHired, handleChangeFilterSelect } = useContext(hiredCandidateCtx);
    const [managerDeleted, setManagerDeleted] = useState(false);
    const [step, setStep] = useState(0);

    const columnUserIds = candidates.map(candidate => candidate.columnUserUuid);
    const candidateIds = candidates.map(candidate => candidate.candidateUuid);
    const { hiringManager: selectedManager, hiredDate: selectedDate } = updatedFields;
    const { name, slug, uuid: assessmentUuid } = assessment;
    const dataForEvents = { candidateIds, ttId: assessmentUuid, ttName: name };

    useEffect(() => {
        setStep(0);
    }, [open]);

    const handleSkip = () => {
        if (step === 0) {
            HiredEvents.CANDIDATE_HIRING_MANAGER_SKIPPED(dataForEvents);
            setUpdatedFields({
                hiringManager: candidates.length === 1 && candidates[0].hiringManager ? candidates[0].hiringManager : {},
                hiredDate: candidates.length === 1 && candidates[0].hiredDate ? candidates[0].hiredDate : new Date()
            });
            setStep(step + 1);
        }
        if (step === 1) {
            HiredEvents.CANDIDATE_HIRE_DATE_SKIPPED(dataForEvents);
            handleClose();
            handleSuccessDialog();
        }
    };

    const handleClose = (skip = false) => {
        onClose(skip);
        fetchFiltersForHired();
        if (managerDeleted) {
            handleChangeFilterSelect(null, 'hiringManager');
            loadHiredCandidates();
        }
    };

    const assignHiringManager = async () => {
        setLoading(true);
        if (!selectedManager) return;
        const { doesNotExist, email, fullName, uuid } = selectedManager;
        let managersUuidToAssign = uuid;
        if (doesNotExist) {
            const { data } = await addHiringManager(slug, email, fullName);
            managersUuidToAssign = data.uuid;
        }
        assignHiringManagerOrDate(slug, selectedDate, managersUuidToAssign, columnUserIds)
            .then(({ success, data }) => {
                if (success && data) {
                    flashMessage('Hiring manager added', 'done');
                    dataForEvents.hiringManagerName = updatedFields.hiringManager.fullName;
                    dataForEvents.hiringManagerEmail = updatedFields.hiringManager.email;
                    dataForEvents.hiringManagerId = managersUuidToAssign;
                    dataForEvents.context = 'hire survey';
                    HiredEvents.CANDIDATE_HIRING_MANAGER_ADDED(dataForEvents);
                    if (callback) callback();
                    setUpdatedFields({
                        ...updatedFields,
                        hiredDate: candidates.length === 1 && candidates[0].hiredDate ? candidates[0].hiredDate : new Date()
                    });
                    setFullName(fullName);
                    setStep(step + 1);
                    if (updateUserFields) {
                        updateUserFields({ hiringManager: selectedManager });
                    }
                    if (candidates.length === 1 && candidates[0].hiredDate) {
                        handleClose();
                        handleSuccessDialog();
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const assignHiringDate = async () => {
        setLoading(true);
        assignHiringManagerOrDate(slug, selectedDate, selectedManager.uuid, columnUserIds)
            .then(({ success, data }) => {
                if (success && data) {
                    flashMessage('Hired date added', 'done');
                    dataForEvents.hireDate = selectedDate;
                    dataForEvents.context = 'hire survey';
                    HiredEvents.CANDIDATE_HIRE_DATE_ADDED(dataForEvents);
                    if (callback) callback();
                    setStep(step + 1);
                    if (updateUserFields) {
                        updateUserFields({ hiredDate: selectedDate });
                    }
                    handleClose();
                    handleSuccessDialog();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSuccessDialog = () => {
        if (showSuccessDialog && onSuccessClose) onSuccessClose();
        setShowSuccessDialog(!showSuccessDialog);
    };

    const setSelectedManager = (newValue) => {
        setUpdatedFields({ ...updatedFields, hiringManager: newValue });
    };

    const setSelectedDate = (newValue) => {
        setUpdatedFields({ ...updatedFields, hiredDate: newValue });
    };


    let titleComponent = 'Who is the hiring manager?';
    const step0Label = candidates.length === 1 && candidates[0].hiredDate ? 'Finish' : 'Next';
    let actionButton = (
        <Button
            className={clsx(classes.mainButton, !selectedManager.email && classes.greyButton)}
            color="primary"
            variant="contained"
            onClick={assignHiringManager}
            disabled={!selectedManager.email || loading}
        >
            {
                loading ? (
                    <CircularProgress
                        size={20}
                        className={classes.loader}
                    />
                ) : step0Label
            }
        </Button>
    );

    if (step === 1) {
        titleComponent = 'What date was the candidate hired?';
        actionButton = (
            <Button
                className={clsx(classes.mainButton, !selectedManager.email && classes.greyButton)}
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={assignHiringDate}
            >
                {
                    loading ? (
                        <CircularProgress
                            size={20}
                            className={classes.loader}
                        />
                    ) : <span>Finish</span>
                }
            </Button>
        );
    }

    return (
        <>
            <FinishDialog
                open={showSuccessDialog}
                candidatesLength={columnUserIds.length}
                handleClose={handleSuccessDialog}
            />
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={() => handleClose(true)}
                handleClose={() => handleClose(true)}
                titleComponent={titleComponent}
                actionClassName={classes.buttonContainer}
                actionComponent={(
                    <>
                        <Button
                            color="primary"
                            className={classes.notSureButton}
                            onClick={handleSkip}
                        >
                            Not sure? Skip and add later
                        </Button>
                        {actionButton}
                    </>
                )}
            >
                {
                    step === 0 && (
                        <HiringManagerContent
                            hiringManager={updatedFields.hiringManager}
                            hiringManagerFullName={hiringManagerFullName}
                            setFullName={setFullName}
                            assessmentSlug={slug}
                            setManagerDeleted={setManagerDeleted}
                            selected={selectedManager}
                            setSelected={setSelectedManager}
                            dataForEvents={dataForEvents}
                            setHiringManager={hiringManager => setUpdatedFields({ ...updatedFields, hiringManager })}
                        />
                    )
                }
                {
                    step === 1 && (
                        <DateSelectionContent
                            assessmentName={name}
                            candidates={candidates}
                            selected={selectedDate}
                            setSelected={setSelectedDate}
                            dataForEvents={dataForEvents}
                        />
                    )
                }
            </Dialog>
        </>
    );
});

export default withStyles(styles)(withRouter(HiringFlowDialog));
