import React from 'react';
import { withStyles } from '@mui/styles';
import parse from 'html-react-parser';
import styles from './styles';

const HtmlDescription = ({ htmlDescription, classes }) => (
    <div className={classes.textStyle}>
        {parse(htmlDescription)}
    </div>
);

export default withStyles(styles)(HtmlDescription);
