import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import { deactivateAssessment } from 'requests/AssessmentRequests';
import { onDeactivateCallback } from 'helper/assessmentFunctions';

import exclamationMarkIcon from 'img/exclamationMark.svg';

const styles = () => ({
    actionButton: {
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 20
    },
    deactivateButton: {
        paddingRight: 40,
        paddingLeft: 40,
        fontWeight: 700,
        minWidth: 184
    },
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    },
    modalContent: {
        textAlign: 'center',
        margin: '35px 75px 12px'
    },
    modalContentItem: {
        margin: '10px 0',
        fontWeight: 'bold',
        fontSize: 20
    }
});

const DeactivateAssessmentDialog = observer(({ open, onClose, classes, audition, onSuccess, flashMessage }) => {
    const [loading, setLoading] = useState(false);

    const onDeactivate = () => {
        setLoading(true);
        onDeactivateCallback(deactivateAssessment(audition.slug))
            .then(({ success, data }) => {
                if (success && onSuccess) {
                    onSuccess(data.inActivatedAt);
                }
                onClose();
                flashMessage('Assessment was closed', 'done');
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <Dialog
            maxWidth="sm"
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className={classes.actionButton}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.deactivateButton}
                        onClick={onDeactivate}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : <span>Close Assessment</span>
                        }
                    </Button>
                </>
            )}
            open={open}
            onClose={onClose}
            handleClose={onClose}
        >
            <div className={classes.modalContent}>
                <img src={exclamationMarkIcon} alt="!" />
                <div className={classes.modalContentItem}>Are you sure you want to close this assessment?</div>
                <div>By closing this assessment access via shared links will be disabled. Candidate data will be retained.</div>
            </div>
        </Dialog>
    );
});

export default withRouter(withStyles(styles)(DeactivateAssessmentDialog));
