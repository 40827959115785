import React from 'react';
import { withStyles } from '@mui/styles';
import HtmlQuestionDescription from 'components/assessments_pages/HtmlDescription';

const styles = theme => ({
    wrapper: {
        marginBottom: 25,
        marginTop: 20,
        color: theme.palette.grey[900]
    },
    mediaWrapper: {
        marginTop: '15px'
    }
});

const TypeWrapper = ({ description, children, className, classes }) => (
    <div className={`${classes.wrapper} ${className}`}>
        <HtmlQuestionDescription htmlDescription={description} />
        {Boolean(children) && <div className={classes.mediaWrapper}>{children}</div>}
    </div>
);

export default withStyles(styles)(TypeWrapper);
