import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import Dialog from 'libraries/Dialog';
import { deleteSet } from 'requests/CMSRequests';
import CircularProgress from '@mui/material/CircularProgress';
import { appCtx } from 'components/appStore';
import { checkPluralNew } from 'helper/commonFunctions';

const DeleteSetDialog = observer(({
    onSuccess, openedDialog, handleDialog, selectedSetData
}) => {
    const [loading, setLoading] = useState(false);

    const { flashMessage } = useContext(appCtx);

    if (!selectedSetData) return null;

    const { uuid, assessmentsUsing } = selectedSetData;

    const submit = () => {
        setLoading(true);
        deleteSet(uuid)
            .then(() => {
                handleDialog();
                flashMessage('Set removed', 'done');
                if (onSuccess) onSuccess();
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'done');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onClose = () => {
        handleDialog();
    };

    return (
        <Dialog
            onClose={onClose}
            handleClose={onClose}
            titleComponent="Are you sure you want to delete this set?"
            maxWidth="sm"
            actionComponent={(
                <>
                    <Button
                        className="u-txt--bold"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className="u-pdn--rx5 u-pdn--lx5"
                        disabled={loading}
                        onClick={submit}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                />
                            ) : <span>Delete set</span>
                        }
                    </Button>
                </>
            )}
            open={openedDialog}
        >
            This set is active in <b>{assessmentsUsing.length} {checkPluralNew(assessmentsUsing.length, 'assessment')}</b> and will be removed from every instance.
            Questions in set won`t be deleted. If this set is used in public assessments, it will be removed from them in Public Library as well.
            {
                assessmentsUsing.map(({ name, uuid: assessmentUuid }) => (
                    <div
                        key={assessmentUuid}
                        className="t-txt--brand-new u-txt--14 u-txt--semibold u-mrg--tx2"
                    >
                        {name}
                    </div>
                ))
            }
        </Dialog>
    );
});

export default DeleteSetDialog;
