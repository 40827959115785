import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { hiredCandidateCtx } from 'pages/candidates/HiredCandidates/hiredCandidateStore';
import Select from 'libraries/Select';
import { removeCandidateFromHired } from 'requests/CandidatesRequests';
import HiredEvents from 'events/HiredEvents';
import { appCtx } from '../../appStore';

const styles = theme => ({
    text: {
        marginTop: 12,
        fontWeight: 600,
        color: theme.palette.grey[700]
    },
    loader: {
        color: '#fff'
    }
});

const RemoveFromHiredDialog = observer(({
    candidate, classes, open, onClose, callback
}) => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState(null);
    const { loadHiredCandidates, fetchFiltersForHired,
        totalCount, filterAudition, clearFiltersSelect,
        filterHiringManager, handleChangeFilterSelect,
        query, clearQuery
    } = useContext(hiredCandidateCtx);
    const { flashMessage } = useContext(appCtx);
    const { candidateFullName, columnUserUuid,
        assessmentTitle: ttName, hiredDate,
        assessmentUuid: ttId, candidateUuid: candidateId,
        hiringManager, fullName
    } = candidate;

    const options = [
        'I made a mistake',
        'Candidate never started',
        "Candidate wasn't suitable",
        'Just trying out the feature'
    ];

    const handleChangeReason = (e) => {
        const { value } = e.target;
        setReason(value);
    };

    const handleClose = () => {
        onClose();
        setReason(null);
    };

    const removeFromHired = () => {
        setLoading(true);
        removeCandidateFromHired(columnUserUuid)
            .then(() => {
                handleClose();
                flashMessage('Candidate was removed from hired', 'done');
                if (callback) callback();
                if (totalCount === 1) {
                    if (query) {
                        clearQuery();
                        loadHiredCandidates();
                    }
                    if (filterAudition && !filterHiringManager) handleChangeFilterSelect(null, 'audition');
                    if (filterHiringManager && !filterAudition) handleChangeFilterSelect(null, 'hiringManager');
                    if (filterHiringManager && filterAudition) clearFiltersSelect();
                    if (!query && !filterHiringManager && !filterAudition) loadHiredCandidates();
                } else {
                    loadHiredCandidates();
                }
                fetchFiltersForHired();
                const dataForEvent = {
                    candidateId,
                    ttId,
                    ttName,
                    candidateHired: true,
                    hiringManagerName: hiringManager ? hiringManager.fullName : null,
                    hiringManagerId: hiringManager ? hiringManager.uuid : null,
                    hireDate: hiredDate || null,
                    reason
                };
                HiredEvents.CANDIDATE_UNHIRED(dataForEvent);
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            titleComponent="Are you sure you want to unhire candidate?"
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            actionComponent={
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        onClick={handleClose}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={removeFromHired}
                        disabled={loading}
                        className="u-pdn--lx8 u-pdn--rx8"
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : <span>Remove Candidate</span>
                        }
                    </Button>
                </>
            }
        >
            <div className={classes.text}>
                {`You are about to remove ${candidateFullName || fullName} from hired. The hiring manager won’t receive a candidate survey.`}
            </div>
            <Select
                label={<>Reason for removing candidate&nbsp;</>}
                className="u-mrg--tx5 u-mrg--bx3"
                margin="none"
                value={reason}
                onChange={handleChangeReason}
                variant="outlined"
                options={options.map(option => ({ value: option, label: option }))}
            />
        </Dialog>
    );
});

export default withStyles(styles)(RemoveFromHiredDialog);
