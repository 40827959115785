import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import { prolongPauseSubscription } from 'requests/SubscriptionRequests';
import { appCtx } from 'components/appStore';
import { MAX_PAUSES_COUNT } from 'helper/constants';

import styles from './styles';

const PauseButton = observer(({
    classes, daysOfPauseLeft, getCompanyPlan, pausesCount
}) => {
    const [pauseAgainDialogOpen, setPauseAgainDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { flashMessage, updateCompany } = useContext(appCtx);

    const handlePauseAgainDialog = () => {
        setPauseAgainDialogOpen(!pauseAgainDialogOpen);
    };

    const handlePause = () => {
        setLoading(true);
        prolongPauseSubscription()
            .then(({ success, data }) => {
                if (success) {
                    getCompanyPlan();
                    flashMessage('Your account subscription has been paused.');
                    updateCompany(data);
                    handlePauseAgainDialog();
                }
            })
            .catch(({ response: { data } }) => {
                if (data.errors) {
                    flashMessage(data.errors.message);
                } else {
                    flashMessage('Something went wrong.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {
                daysOfPauseLeft <= 7 && !(pausesCount >= MAX_PAUSES_COUNT) && (
                    <Button
                        color="primary"
                        className="u-txt--bold u-mrg--rx3"
                        onClick={handlePauseAgainDialog}
                    >
                        Pause again
                    </Button>
                )
            }
            <Dialog
                maxWidth="sm"
                titleComponent="Are you sure you want to pause your subscription?"
                handleClose={handlePauseAgainDialog}
                onClose={handlePauseAgainDialog}
                open={pauseAgainDialogOpen}
                actionComponent={(
                    <>
                        <Button
                            className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                            onClick={handlePauseAgainDialog}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={loading}
                            onClick={handlePause}
                            className={classes.pauseButton}
                        >
                            {
                                loading ? (
                                    <CircularProgress
                                        size={24}
                                        thickness={3}
                                        className={classes.loader}
                                    />
                                ) : <span>Pause Subscription</span>
                            }
                        </Button>
                    </>
                )}
            >
                After <b>3 months</b> your account will be automatically unpaused and unable to be repaused for a period of <b>6 months</b>.
                <br /><br />
                The access to your candidates, assessments and data will be locked, however you can unpause at any time.
            </Dialog>
        </>
    );
});

export default withStyles(styles)(PauseButton);
