import React, { useState } from 'react';
import Moment from 'react-moment';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Popover from 'libraries/Popover';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import LockIcon from 'img/lock.svg';
import styles from './styles';


const AuditionListSelect = observer(({
    classes, opportunitiesList,
    selectedAuditionIndex, selectedOpportunityIndex,
    setSelectedIndexes, isLoadingAudition, showStatus = true
}) => {
    const [popover, setPopover] = useState(null);

    if (!opportunitiesList || !opportunitiesList.length
        || selectedOpportunityIndex === undefined
        || selectedAuditionIndex === undefined) return null;

    const returnAssessmentItem = (opportunityIndex, assessmentIndex) => {
        const { audition, userAudition } = opportunitiesList[opportunityIndex].columns[assessmentIndex];
        if (!audition) return null;
        const { name } = audition;
        const { completedAt, id, completionsLimitExceeded } = userAudition;
        const active = selectedOpportunityIndex === opportunityIndex && selectedAuditionIndex === assessmentIndex;
        return (
            <MenuItem
                key={id}
                onClick={() => {
                    setSelectedIndexes(opportunityIndex, assessmentIndex);
                    closePopover();
                }}
                className={clsx(classes.assessmentWrapper, active && classes.assessmentWrapperActive)}
                disabled={completionsLimitExceeded}
            >
                <div className={classes.name}>
                    <TruncateWithTooltip text={name} width={250} />
                </div>
                {
                    completedAt ? (
                        <div className={classes.date}>
                            <Moment format="D MMMM, YYYY">{completedAt}</Moment>
                        </div>
                    ) : <div className={classes.date}>Incomplete</div>
                }
                <IconButton className={classes.iconButton}>
                    {
                        completionsLimitExceeded ? <img src={LockIcon} alt="lock" /> : <KeyboardArrowRightIcon className={classes.icon} />
                    }
                </IconButton>
            </MenuItem>
        );
    };

    const closePopover = () => {
        if (popover) {
            popover.handleClose();
        }
    };

    if (opportunitiesList.every(({ columns }) => columns?.every(({ audition }) => !audition))) {
        return (
            <Typography variant="h4">
                Candidate has no started assessments
            </Typography>
        );
    }

    const auditionColumn = opportunitiesList[selectedOpportunityIndex]?.columns[selectedAuditionIndex];

    const returnAssessmentStatusLabel = () => {
        if (!auditionColumn) return null;
        if (auditionColumn.audition.removed) {
            return <span className={clsx(classes.status, classes.inActiveStatus)}>Deleted</span>;
        }
        if (auditionColumn.audition.archived) {
            return <span className={clsx(classes.status, classes.inActiveStatus)}>Archived</span>;
        }
        return (
            <span className={clsx(classes.status, auditionColumn.audition.active ? classes.activeStatus : classes.inActiveStatus)}>
                {auditionColumn.audition.active ? 'Open' : 'Closed'}
            </span>
        );
    };

    const auditionName = (
        <Typography variant="h4" className="u-txt--left u-txt--prewrap">
            {(auditionColumn?.audition) ? (
                <>
                    {auditionColumn.audition.name}
                    { showStatus && returnAssessmentStatusLabel() }
                </>
            ) : <span>Report Cards</span>}
        </Typography>
    );

    if (opportunitiesList.length === 1) {
        return auditionName;
    }

    return (
        <Popover
            className={classes.popoverRoot}
            onMounted={callbacks => setPopover(callbacks)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            label={(
                <Button>
                    {auditionName}
                    <ExpandMoreIcon className="u-mrg--lx1" />
                    {isLoadingAudition && (
                        <CircularProgress color="primary" className="u-mrg--lx2" size={20} />
                    )}
                </Button>
            )}
        >
            <div className={classes.headerWrapper}>
                <Typography variant="h6">Report Cards</Typography>
                <p className={classes.subtopic}>select an assessment to view candidate results</p>
            </div>
            <div className={classes.opportunitiesWrapper}>
                {
                    opportunitiesList.map(({ opportunity: { jobTitle, assessmentFlow }, columns }, opportunityIndex) => {
                        if (assessmentFlow) {
                            return returnAssessmentItem(opportunityIndex, 0);
                        }

                        return (
                            <Accordion
                                classes={{
                                    root: classes.accordion,
                                    expanded: classes.accordionExpanded
                                }}
                            >
                                <AccordionSummary
                                    classes={{
                                        root: clsx(classes.accordionSummary, opportunityIndex === selectedOpportunityIndex && classes.accordionSummaryActive),
                                        expanded: classes.accordionSummaryExpanded,
                                        content: classes.accordionSummaryContent,
                                        expandIcon: 'u-pdn--x2'
                                    }}
                                    expandIcon={<ExpandMoreIcon className={classes.expandAccordionIcon} />}
                                >
                                    <TruncateWithTooltip text={jobTitle} width={270} />
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    { columns.map((column, assessmentIndex) => returnAssessmentItem(opportunityIndex, assessmentIndex)) }
                                </AccordionDetails>
                            </Accordion>
                        );
                    })
                }
            </div>
        </Popover>
    );
});

export default withStyles(styles)(AuditionListSelect);
