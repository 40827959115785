import React from 'react';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';

const DeleteBrandingSetDialog = observer(({
    open, onClose,
    brandingSet: { title, countOfAuditions },
    deleteBranding,
    isSaving
}) => {
    const handleClose = () => {
        if (isSaving) return;
        onClose();
    };

    return (
        <Dialog
            maxWidth="sm"
            onClose={handleClose}
            handleClose={handleClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={handleClose}
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="u-pdn--lx7 u-pdn--rx7 u-wdt--200"
                        color="secondary"
                        variant="contained"
                        onClick={deleteBranding}
                        disabled={isSaving}
                    >
                        {isSaving ? <CircularProgress size={24} /> : <span>Delete</span>}
                    </Button>
                </>
            )}
            titleComponent={`Delete '${title}' branding set`}
            open={open}
        >
            <>
                Are you sure you want to delete '{title}' branding set?&nbsp;
                It is used in {countOfAuditions} assessment{countOfAuditions !== 1 && 's'}
            </>
        </Dialog>
    );
});

export default DeleteBrandingSetDialog;
