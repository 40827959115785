import React from 'react';
import { withStyles } from '@mui/styles';
import { secondsToTime } from '../../../helper/commonFunctions';

const styles = {
    questionTimeLimit: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: 12,
        color: '#3a3c43',
        fontWeight: 600,
        marginLeft: 8
    }
};

const RemainingTimeLabel = ({ classes, question, auditionTimerEnabled }) => (
    <span className={classes.questionTimeLimit}>
        {question.timerEnabled && !auditionTimerEnabled
            ? <>
                <span>{secondsToTime(question.timeLimit * 60)}</span>&nbsp;<span>REMAINING</span>
            </>
            : <span />}
    </span>
);

export default withStyles(styles)(RemainingTimeLabel);
