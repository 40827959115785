export default theme => ({
    rootStyle: {
        position: 'relative',
        paddingTop: 0,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 32,
        width: '48%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 288
        }
    },
    radioStyle: {
        position: 'absolute',
        bottom: 18,
        left: 19,
        border: `2px solid ${theme.palette.primary.main}`,
        width: 16,
        height: 16,
        zIndex: 2
    },
    orderStyle: {
        position: 'absolute',
        bottom: 18,
        left: 19,
        width: 16,
        height: 16,
        zIndex: 2
    },
    answerWrapper: {
        position: 'relative',
        display: 'block',
        width: '100%',
        height: 372,
        backgroundColor: theme.palette.grey[100],
        padding: '9px 17px 17px',
        borderRadius: 4,
        border: `2px solid ${theme.palette.grey[200]}`,
        [theme.breakpoints.down('lg')]: {
            height: 276
        },
        [theme.breakpoints.down('sm')]: {
            height: 286
        },
        cursor: 'default'
    },
    headerWrapper: {
        width: '100%',
        backgroundColor: theme.palette.grey[100]
    },
    answerTextContainer: {
        position: 'absolute',
        minHeight: 45,
        display: 'flex',
        width: 'calc(100% - 33px)',
        alignItems: 'center',
        userSelect: 'none',
        left: 17
    },
    collapse: {
        backgroundColor: theme.palette.grey[100],
        zIndex: 7,
        paddingBottom: 7
    },
    collapseContainer: {
        width: '100%'
    },
    openedCollapse: {
        width: '100%',
        maxHeight: 306,
        overflow: 'auto',
        [theme.breakpoints.down('lg')]: {
            maxHeight: 213
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: 224
        }
    },
    noMediaBox: {
        maxWidth: '100%',
        height: 'calc(100% - 83px)',
        backgroundColor: theme.palette.grey[400],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        marginTop: 8
    },
    mediaWrapper: {
        maxWidth: '99.8%',
        height: 'calc(100% - 83px)',
        backgroundColor: theme.palette.grey[400],
        display: 'flex',
        cursor: 'default',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        marginTop: 50
    },
    mediaWrapperPlayer: {
        position: 'relative'
    },
    showMore: {
        color: theme.palette.primary.main,
        fontSize: 10,
        cursor: 'pointer',
        display: 'inline-block'
    }
});
