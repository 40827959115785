import React, { useContext, useEffect, useState } from 'react';

import { withStyles } from '@mui/styles';

import Zendesk from 'helper/zendeskFunctions';
import Typography from '@mui/material/Typography';

import LoaderCalibration from 'components/loaders/LoaderCalibration';
import Loader from 'components/loaders/Loader';
import DiamondTooltip from 'components/subscription/DiamondTooltip';
import { NO_ANSWER, SIMULATION_GROUP, SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR } from 'helper/constants';
import { checkHasCompanyFeature } from 'helper/commonFunctions';

import {
    changeQuestionParams,
    getQuestionInfo,
    saveQuestionSettings
} from 'requests/QuestionsRequests';

import AnswerType from './components/AnswerType';
import QuestionType from './components/QuestionType';
import SimulationList from './components/SimulationList';
import AnswerTypes from './components/AnswerTypes';
import CorrectAnswerInputs from './components/CorrectAnswerInputs';
import SkillInput from './components/SkillInput';
import SkillInputCMS from './components/SkillInputCMS';
import EditQuestionStatuses from './components/EditQuestionStatuses';
import QuestionCategory from './components/QuestionCategory';

import AdvancedSettings from './components/AdvancedSettings';
import ScoringInstructions from './components/ScoringInstructions';
import AutoProgressWarning from './components/AutoProgressWarning';
import ScreeningContent from './components/ScreeningContent';

import { appCtx } from '../../appStore';

import styles from './styles';

const loaderStyles = {
    zIndex: '1000',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255, .3)'
};


const EditQuestion = ({ // can be also used in CMS
    questionId, updateQuestion: updateQuestionProp, isCMS = false,
    deleteQuestion, savingQuestion, activeQuestionIndex,
    audition = {}, classes, questions = [], selectQuestion,
    setSaving, QuestionHeaderComp, getAssessment,
    assessmentHasTypingTest, activeTab, setActiveTab,
    handlePreviewMode, totalQuestionsNumber, isScreening
}) => {
    const [question, setQuestion] = useState(null);
    const [loading, setLoading] = useState(false);

    const { flashMessage, company } = useContext(appCtx);

    useEffect(() => {
        setLoading(true);
        fetchQuestion();
    }, [questionId]);

    useEffect(() => () => {
        Zendesk.show();
    }, [questionId]);

    const premiumQuestionTypesAvailable = checkHasCompanyFeature(company, 'CREATE_PREMIUM_QUESTIONS');

    const updateQuestion = (data) => {
        setQuestion({ ...question, ...data });
        if (updateQuestionProp) updateQuestionProp(data);
    };

    const fetchQuestion = () => (
        getQuestionInfo(questionId, isCMS, isScreening)
            .then(({ success, data }) => {
                if (data && success) {
                    updateQuestion(data);
                    setQuestion(data);
                    onUpdateQuestion(data);
                }
                setLoading(false);
                return data;
            })
            .catch(() => {
                setLoading(false);
            })
    );

    const changeQuestionSettings = (data) => {
        if (SIMULATION_GROUP.types.includes(question.answerType) && !isCMS) return;
        setSaving(true);
        saveQuestionSettings(questionId, data, isCMS)
            .then(({ success, data: questionData }) => {
                if (success && questionData) {
                    const { id, ...other } = questionData;
                    const newQuestion = { ...question, ...other, synchronized: false };
                    setQuestion(newQuestion);
                    updateQuestion(newQuestion);
                    onUpdateQuestion(questionData);
                }
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const onUpdateQuestion = (questionData) => {
        if (!isCMS && question.valid !== questionData.valid
            && getAssessment
        ) {
            getAssessment();
        }
    };

    const changeQuestion = (data) => {
        setSaving(true);
        return changeQuestionParams(questionId, data, isCMS)
            .then(({ success, data: questionData }) => {
                if (success && questionData) {
                    setQuestion(questionData);
                    updateQuestion(questionData);
                    onUpdateQuestion(questionData);
                }
                setSaving(false);
            })
            .catch((e) => {
                setSaving(false);
                const errorData = e?.response?.data;
                flashMessage(errorData?.msg || 'Something went wrong', 'error');
            });
    };

    if (isScreening) {
        return (
            <div className={classes.wrapper}>
                {
                    loading && (
                        <LoaderCalibration
                            className="u-mrg--tx20"
                            widthOuter={100}
                        />
                    )
                }
                {
                    question && (
                        <ScreeningContent
                            loading={loading}
                            question={question}
                            setSaving={setSaving}
                            questions={questions}
                            questionId={questionId}
                            setQuestion={setQuestion}
                            fetchQuestion={fetchQuestion}
                            updateQuestion={updateQuestion}
                            deleteQuestion={deleteQuestion}
                            savingQuestion={savingQuestion}
                            onUpdateQuestion={onUpdateQuestion}
                            activeQuestionIndex={activeQuestionIndex}
                            QuestionHeaderComp={QuestionHeaderComp}
                            handlePreviewMode={handlePreviewMode}
                            totalQuestionsNumber={totalQuestionsNumber}
                        />
                    )
                }
            </div>
        );
    }

    return (
        <>
            { isCMS && question && (
                <div className={classes.status}>
                    <EditQuestionStatuses
                        question={question}
                        setQuestion={setQuestion}
                        flashMessage={flashMessage}
                        getAssessment={getAssessment}
                    />
                </div>
            )}
            <div className={classes.wrapper}>
                {
                    QuestionHeaderComp && (
                        <QuestionHeaderComp
                            question={question}
                            questions={questions}
                            savingQuestion={savingQuestion}
                            activeQuestion={activeQuestionIndex}
                            deleteQuestion={deleteQuestion}
                            handlePreviewMode={handlePreviewMode}
                            totalQuestionsNumber={totalQuestionsNumber}
                        />
                    )
                }
                {
                    loading && (
                        <LoaderCalibration
                            className="u-mrg--tx20"
                            widthOuter={100}
                        />
                    )
                }
                {
                    question && !loading && (
                        <div>
                            {
                                !question.answerType && (
                                    <div className="u-dsp--distribute u-mrg--bx2">
                                        <Typography variant="h6">
                                            Select your question type
                                        </Typography>
                                        {
                                            !premiumQuestionTypesAvailable && (
                                                <DiamondTooltip />
                                            )
                                        }
                                    </div>
                                )
                            }
                            <AnswerTypes
                                isCMS={isCMS}
                                question={question}
                                setSaving={setSaving}
                                interview={audition}
                                setQuestion={setQuestion}
                                updateQuestion={updateQuestion}
                                onUpdateQuestion={onUpdateQuestion}
                                assessmentHasTypingTest={assessmentHasTypingTest}
                            />
                            {
                                (SIMULATION_GROUP.types.includes(question.answerType) && !isCMS && (!question.createdFromSimulationType || question.createdFromSimulationType !== question.answerType)) ? (
                                    <SimulationList
                                        {...{
                                            audition,
                                            question,
                                            questions,
                                            activeQuestionIndex,
                                            activeTab,
                                            setActiveTab,
                                            selectQuestion,
                                            updateQuestion,
                                            assessmentHasTypingTest
                                        }}
                                    />
                                ) : (
                                    <>
                                        {
                                            (question && ![SALES_SIMULATOR, QA_SIMULATOR, MARKETING_SIMULATOR].includes(question.answerType)) && (
                                                <QuestionType
                                                    {...{
                                                        isCMS,
                                                        question,
                                                        fetchQuestion,
                                                        changeQuestion
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            isCMS ? (
                                                <SkillInputCMS
                                                    {...{
                                                        isCMS,
                                                        question,
                                                        audition,
                                                        setSaving,
                                                        setQuestion
                                                    }}
                                                />
                                            ) : (
                                                <SkillInput
                                                    {...{
                                                        isCMS,
                                                        question,
                                                        audition,
                                                        setSaving,
                                                        updateQuestion
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            isCMS && (
                                                <QuestionCategory
                                                    {...{
                                                        isCMS,
                                                        question,
                                                        audition,
                                                        setSaving,
                                                        setQuestion
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            question.answerType && (
                                                <>
                                                    <AutoProgressWarning audition={audition} />
                                                    <AnswerType
                                                        {...{
                                                            isCMS,
                                                            questionsNumber: questions.length,
                                                            question,
                                                            setQuestion,
                                                            fetchQuestion,
                                                            changeQuestion,
                                                            setSaving,
                                                            changeQuestionSettings
                                                        }}
                                                    />
                                                    <CorrectAnswerInputs
                                                        isCMS={isCMS}
                                                        question={question}
                                                        setSaving={setSaving}
                                                        fetchQuestion={fetchQuestion}
                                                        flashMessage={flashMessage}
                                                    />
                                                    {![SALES_SIMULATOR, QA_SIMULATOR, NO_ANSWER, MARKETING_SIMULATOR].includes(question.answerType) && (
                                                        <ScoringInstructions
                                                            isCMS={isCMS}
                                                            question={question}
                                                            changeQuestion={changeQuestion}
                                                        />
                                                    )}
                                                </>
                                            )
                                        }
                                        {
                                            question.answerType !== NO_ANSWER && (
                                                <AdvancedSettings
                                                    {...{
                                                        isCMS,
                                                        changeQuestionSettings,
                                                        question,
                                                        audition
                                                    }}
                                                />
                                            )
                                        }
                                    </>
                                )
                            }
                        </div>
                    )
                }
                <Loader
                    variant="indeterminate"
                    show={savingQuestion}
                    thickness={3}
                    style={loaderStyles}
                />
            </div>
        </>
    );
};

export default withStyles(styles)(EditQuestion);
