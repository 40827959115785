import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';


import exclamationMarkIcon from 'img/exclamationMark.svg';

const styles = () => ({
    cancelButton: {
        fontWeight: 700,
        paddingRight: 20,
        paddingLeft: 20
    },
    submitButton: {
        paddingRight: 40,
        paddingLeft: 40,
        fontWeight: 700,
        minWidth: 174
    },
    loader: {
        color: '#fff',
        '& svg': {
            margin: 0
        }
    },
    modalContent: {
        textAlign: 'center',
        margin: '35px 22px 12px'
    },
    modalContentItem: {
        margin: '25px 0',
        fontWeight: 'bold',
        fontSize: 20
    }
});

const SmsCreditWarningDialog = ({
    open, assessment, history, context,
    onClose, classes, countSmsLeft, isQuick,
    candidatesWithPhonesToInviteCount
}) => {
    const { loading, onSubmitValidate } = useContext(context);

    const getContextPage = () => {
        const stepPages = {
            edit: 'Create',
            invite: 'Invite',
            grading: 'Optimize',
            select: 'Select'
        };

        const { pathname } = history.location;
        return `${stepPages[pathname.split('/')[2]]} page`;
    };

    const onSubmit = () => {
        onSubmitValidate({
            isQuick,
            onAllInvitationsSent: onClose,
            assessment,
            context: getContextPage()
        });
    };


    return (
        <Dialog
            maxWidth="sm"
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className={classes.cancelButton}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.submitButton}
                        onClick={onSubmit}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : <span>Send it!</span>
                        }
                    </Button>
                </>
            )}
            open={open}
            onClose={onClose}
            handleClose={onClose}
        >
            <div className={classes.modalContent}>
                <img src={exclamationMarkIcon} alt="!" />
                <div className={classes.modalContentItem}>SMS Credit warning</div>
                <div>
                    Not enough SMS credit available <b>({countSmsLeft})</b> to invite candidates <b>({candidatesWithPhonesToInviteCount})</b>.&nbsp;
                    Shall we send as many as possible? Unsent SMS notifications will be marked in your candidate list.
                </div>
            </div>
        </Dialog>
    );
};

export default withRouter(withStyles(styles)(SmsCreditWarningDialog));
