import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TooltipBlack from 'libraries/Tooltip';
import CreateFolderDialog from './CreateFolderDialog';

import CategoryCard from './CategoryCard';
import FolderCard from './FolderCard';
import addIcon from './addIcon.svg';

import styles from './styles';
import { appCtx, appStore } from '../../appStore';


const AssessmentFolders = observer(({
    classes, folders, getFolders, privateAssessments,
    addFolder, activeFolderId, setActiveFolder, query,
    deleteFolder, renameFolder
}) => {
    const [closed, setClosed] = useState(Boolean(localStorage.getItem('organiserClosed')));
    const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false);
    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        getFolders();
    }, []);

    const onCollapse = () => {
        if (closed) localStorage.removeItem('organiserClosed');
        if (!closed) localStorage.setItem('organiserClosed', 'true');
        setClosed(!closed);
    };

    const label = privateAssessments ? 'Folder' : 'Category';
    const folderNameParam = privateAssessments ? 'name' : 'title';

    const checkIfFolderNameDoesNotExist = name => (
        !folders.find(folder => folder[folderNameParam].trim() === name.trim())
    );

    const renameFolderWrapper = (uuid, title) => {
        if (checkIfFolderNameDoesNotExist(title)) {
            return renameFolder(uuid, title)
                .then(() => {
                    flashMessage(`${label} renamed`, 'done');
                })
                .catch(({ response }) => {
                    if (response) {
                        const { msg } = response.data;
                        if (msg) {
                            flashMessage(msg, 'error');
                        } else {
                            flashMessage('Something went wrong', 'error');
                        }
                    }
                    return Promise.reject();
                });
        }
        flashMessage(`${label} with title '${title}' already exists!`);
        return Promise.reject();
    };

    const deleteFolderWrapper = (id, name) => deleteFolder(id)
        .then(() => {
            if (id === activeFolderId) setActiveFolder(null);
            appStore.flashMessage(`${name || ''} ${label} deleted`, 'done');
        })
        .catch(() => appStore.flashMessage('Something went wrong', 'error'));

    const addFolderWrapper = (name) => {
        if (checkIfFolderNameDoesNotExist(name)) {
            return addFolder(name)
                .then(({ success, data }) => {
                    if (success && data) {
                        flashMessage(`${name} ${label} created`, 'done');
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        const { msg } = err.response.data;
                        if (msg) {
                            flashMessage(msg, 'error');
                        } else {
                            flashMessage('Something went wrong', 'error');
                        }
                    }
                    return Promise.reject();
                });
        }
        appStore.flashMessage(`${label} with title '${name}' already exists!`);
        return Promise.reject();
    };

    const commonProps = {
        query,
        deleteFolder: deleteFolderWrapper,
        renameFolder: renameFolderWrapper,
        setActiveFolder,
        activeFolderId
    };

    return (
        <Accordion
            square
            classes={{ root: classes.accordion, expanded: classes.expanded }}
            expanded={!closed}
        >
            <Container>
                <AccordionSummary
                    classes={{ root: classes.summary }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={classes.summaryWrapper}>
                        <Typography className={classes.heading}>Organiser</Typography>
                        <Button
                            onClick={onCollapse}
                            className={classes.button}
                        >
                            {closed ? <span>Show</span> : <span>Hide</span>}
                        </Button>
                    </div>
                </AccordionSummary>
            </Container>
            <AccordionDetails
                classes={{ root: classes.details }}
            >
                <Container>
                    <div className={classes.foldersContainer}>
                        {folders.map(folder => (privateAssessments ? (
                            <FolderCard
                                key={folder.id}
                                {...commonProps}
                                {...folder}
                            />
                        ) : (
                            <CategoryCard
                                key={folder.id}
                                {...commonProps}
                                {...folder}
                            />)))}
                        {
                            addFolder && (
                                <TooltipBlack label="Create a new folder">
                                    <IconButton
                                        className={classes.addButton}
                                        onClick={() => setAddFolderDialogOpen(!addFolderDialogOpen)}
                                        size="large"
                                    >
                                        <img src={addIcon} alt="+" />
                                    </IconButton>
                                </TooltipBlack>
                            )
                        }
                    </div>
                </Container>
            </AccordionDetails>
            <CreateFolderDialog
                open={addFolderDialogOpen}
                onClose={() => setAddFolderDialogOpen(false)}
                addFolder={addFolderWrapper}
                privateAssessments={privateAssessments}
            />
        </Accordion>
    );
});

export default withStyles(styles)(AssessmentFolders);
