import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    tableRow: {
        cursor: 'pointer'
    },
    skillName: {
        marginLeft: 0
    },
    tableCell: {
        fontSize: 14,
        fontWeight: 400,
        whiteSpace: 'break-spaces',
        paddingLeft: '24px',
        paddingRight: '24px'
    },
    tableCellProgress: {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        textAlign: 'center',
        verticalAlign: 'top',
        paddingTop: '30px'
    },
    answerCount: {
        marginBottom: '10px'
    },
    accordionRow: {
        maxHeight: 0,
        overflow: 'hidden',
        opacity: 0,
        visibility: 'hidden',
        transition: 'max-height 0.1s ease-out 0.3s, opacity 0.1s ease-out 0.3s, visibility 0s linear 0.4s'
    },
    expandedRow: {
        maxHeight: '1000px',
        opacity: 1,
        visibility: 'visible',
        transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out'
    },
    icon: {
        color: '#7F8084',
        transition: 'transform 0.1s ease-out'
    },
    expandedIcon: {
        transform: 'rotate(-180deg)'
    }
}));
