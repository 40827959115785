import React, { useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { applyCandidateFeedbackAgreement } from 'requests/SettingsRequests';
import { CircularProgress } from '@mui/material';

const FeedbackAgreement = ({ company, textStyle, updateCompany }) => {
    const { candidateFeedbackEnabled } = company;
    const [value, setValue] = useState(candidateFeedbackEnabled);
    const [loading, setLoading] = useState(false);

    const agreementRef = useRef();

    const onSwitchChange = () => {
        setValue(!value);
        setLoading(true);

        const text = agreementRef.current.innerHTML;
        applyCandidateFeedbackAgreement({ text }).then(({ success, data }) => {
            if (success) {
                setLoading(false);
                updateCompany({ ...company, candidateFeedbackEnabled: data.enabled });
            }
        });
    };

    const link = loading
        ? <span>(link here)</span>
        : <span><a href="https://help.vervoe.com/hc/en-us/articles/22836747112980-Assessment-Feedback-FAQs-for-Candidates" target="_blank" rel="noopener noreferrer">(link here)</a></span>;

    return (
        <div className="u-mrg--tx2 u-pos--relative">
            {
                loading && (
                    <div className="u-pos--absolute u-dsp--f u-ai--center u-jc--center u-wdt--100p u-hgt--100p">
                        <CircularProgress />
                    </div>
                )
            }
            <div style={{ opacity: loading ? 0.5 : 1 }}>
                <Typography variant="h6" className="u-mrg--bx2">
                    Candidate Feedback
                </Typography>
                <div className="u-dsp--f" style={{ marginLeft: -12 }}>
                    <Switch
                        onChange={onSwitchChange}
                        checked={value}
                        disabled={loading}
                    />
                    <div className={textStyle}>
                        <Typography variant="h6" className="u-mrg--bx1">
                            Enable Candidate Feedback
                        </Typography>
                        <div className="u-txt--12" ref={agreementRef}>
                            <b>Disclaimer: Vervoe Candidate Feedback</b>
                            <dl>
                                <li>Vervoe provides tools and insights to assist you in your candidate evaluation process.
                                    However, we want to be clear that the ultimate decision of whether or not to employ or engage
                                    a candidate rests solely with you, the Employer.
                                </li>
                                <li>Providing feedback to candidates about their performance in the Vervoe assessment may lead to
                                    questions and inquiries. While Vervoe provides a generic FAQ response
                                    &nbsp;{link}&nbsp;
                                    for candidates, you may receive additional queries that require further clarification.
                                </li>
                                <li>In such cases, it is the Employer's responsibility to address any specific questions or concerns
                                    raised by the candidate. Vervoe will redirect any queries requiring further explanation back to you.
                                </li>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackAgreement;
