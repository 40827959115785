import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';

import TableCellCustom from 'components/table_components/table_cell';
import QuestionNumberLabel from 'components/candidate_cards/QuestionNumberLabel';
import QuestionSkillLabel from 'components/candidate_cards/QuestionSkillLabel';
import QuestionContent from 'components/candidate_cards/QuestionContent';

import useStyles from './styles';


const QuestionTableRow = observer(({
    question, candidatesViewedQuestion, answeredRate,
    candidatesAnsweredQuestion, audition
}) => {
    const { questionGroup, sort } = question;
    const {
        tableRow, tableCell, root,
        colorPrimary, bar, tableCellProgress,
        skillName, contentButton
    } = useStyles({ score: answeredRate });

    return (
        <>
            <TableRow className={tableRow}>
                <TableCellCustom
                    align="left"
                    className={clsx(tableCell, 'u-txt--bold u-pdn--lx4  u-pdn--rx0')}
                >
                    <QuestionNumberLabel
                        question={question}
                        questionNumber={sort}
                    />
                </TableCellCustom>
                <TableCellCustom align="left" className={clsx(tableCell, 'u-pdn--lx1 u-pdn--rx2')}>
                    <div className="u-dsp--f u-ai--center u-jc--start">
                        <QuestionContent
                            question={question}
                            questionNumber={sort}
                            audition={audition}
                            buttonClassName={contentButton}
                        />
                        {
                            questionGroup ? (
                                <QuestionSkillLabel
                                    skill={questionGroup.title}
                                    className={skillName}
                                />
                            ) : <span>-</span>
                        }
                    </div>
                </TableCellCustom>
                <TableCellCustom className={clsx(tableCell, 'u-pdn--lx2 u-pdn--rx6')} align="right">
                    {candidatesViewedQuestion}
                </TableCellCustom>
                <TableCellCustom className={clsx(tableCell, tableCellProgress, 'u-pdn--lx6 u-pdn--rx4')}>
                    <div className="u-dsp--f u-ai--center u-jc--start">
                        {candidatesAnsweredQuestion}
                        <LinearProgress
                            classes={{ root, colorPrimary, bar }}
                            variant="determinate"
                            value={answeredRate}
                        />
                        {answeredRate}%
                    </div>
                </TableCellCustom>
            </TableRow>
        </>
    );
});

export default withRouter(QuestionTableRow);
