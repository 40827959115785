import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import { checkHasUserPermission } from 'helper/commonFunctions';
import GeneralEvents from 'events/GeneralEvents';
import { setupGuideStore } from 'components/layout/SetupGuide/store';
import { appCtx } from 'components/appStore';
import {
    getEmployerGradeQuestion, gradeEmployerAnswer,
    getAuditionQuestionResults, getAuditionScreeningQuestionResults
} from 'requests/JobOpportunityRequests';
import { AUTOGRADED_ANSWER_TYPES } from 'helper/constants';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import GradeCardNavigation from './components/GradeCardNavigation';
import TasksGradeBlock from './components/TasksGradeBlock';
import SimpleGradeBlock from './components/SimpleGradeBlock';
import ScreenList from './components/ScreenList';
import AuthDialog from '../../AuthDialog';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        paddingLeft: 86,
        zIndex: 100,
        borderRadius: 4,
        borderTop: `2px solid ${theme.palette.grey[200]}`,
        borderBottom: `2px solid ${theme.palette.grey[200]}`,
        borderRight: `2px solid ${theme.palette.grey[200]}`,
        minHeight: ({ totalCount }) => totalCount * 70 + 60
    },
    wrapperExtented: {
        paddingLeft: 170
    }
}));


const GradeBlock = observer(({
    candidate, audition, updateUserAuditionAndBox,
    loadGradeQuestion = getEmployerGradeQuestion,
    gradeFunction = gradeEmployerAnswer,
    getAuditionResults = getAuditionQuestionResults,
    getAuditionScreeningResults = getAuditionScreeningQuestionResults,
    gradeableCard, propPublic, authorizeGuest, isIntegration
}) => {
    const [questionNumber, setQuestionNumber] = useState(0);
    const [taskNumber, setTaskNumber] = useState(0);
    const [questionResults, setQuestionResults] = useState([]);
    const [screenQuestionResults, setScreenQuestionResults] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentGrade, setCurrentGrade] = useState(null);
    const [gradeLoading, setGradeLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogAuthOpen, setDialogAuthOpen] = useState(false);
    const [unSavedGrade, setUnSavedGrade] = useState(null);
    const [questionResultIndex, setQuestionResultIndex] = useState(0);
    const [screenSelected, setScreenSelected] = useState(false);

    const { company, flashMessage } = useContext(appCtx);

    const classes = useStyles({
        totalCount: Math.max((questionResults.length + (screenQuestionResults.length ? 1 : 0)),
        currentQuestion?.candidateTasks?.length || 0)
    });

    const { candidateId, userAuditionId, candidateUuid, fullName, completed } = candidate;
    const { uuid: ttId, name: ttName, permissions } = audition;
    const showTasks = Boolean(currentQuestion?.candidateTasks?.length);

    useEffect(() => {
        setQuestionNumber(0);
        setTaskNumber(0);
        getResults();
        getScreeningResults();
    }, [userAuditionId]);

    useEffect(() => {
        if (!currentQuestion) return;
        setGradeQuestion(currentQuestion);
        setQuestionResultIndex(0);
    }, [taskNumber, currentQuestion]);

    const getQuestion = (id) => {
        loadGradeQuestion(id)
            .then(({ data, success }) => {
                if (success) {
                    setGradeQuestion(data);
                }
            });
    };

    const returnCurrentTask = question => (question.candidateTasks?.length ? question?.candidateTasks[taskNumber] : null);

    const getResults = () => {
        if (!completed) {
            setScreenSelected(true);
            return;
        }

        setIsLoading(true);
        getAuditionResults(userAuditionId)
            .then(({ success, data }) => {
                if (success) {
                    setQuestionResults(data);
                    if (!data.length) {
                        setScreenSelected(true);
                    } else {
                        getQuestion(data[0].id);
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getScreeningResults = () => {
        getAuditionScreeningResults(userAuditionId)
            .then(({ success, data }) => {
                if (success) {
                    setScreenQuestionResults(data);
                }
            });
    };

    const setGradeQuestion = (question = currentQuestion) => {
        let newQuestion = question;
        const currentTask = returnCurrentTask(question);
        if (currentTask) {
            // eslint-disable-next-line prefer-destructuring
            newQuestion = currentTask.questionResults[questionResultIndex];
        }
        const { question: { willBeGraded, answerType } } = newQuestion;
        const isAutograded = AUTOGRADED_ANSWER_TYPES.some(el => el === answerType) && willBeGraded;
        setCurrentQuestion(question);
        if (isAutograded) {
            setCurrentGrade(newQuestion.grade);
        } else if (!willBeGraded) {
            setCurrentGrade(null);
        } else {
            setCurrentGrade((gradeableCard || !propPublic) ? newQuestion.userGrade : newQuestion.grade);
        }
    };

    const gotoQuestion = (key) => {
        setScreenSelected(false);
        setQuestionNumber(parseInt(key, 10));
        setCurrentQuestion(null);
        setTaskNumber(0);
        getQuestion(questionResults[key].id);
    };

    const openAuthorisationDialog = (notSavedGrade) => {
        setDialogAuthOpen(true);
        setUnSavedGrade(notSavedGrade);
    };

    const closeAuthorisationDialog = () => {
        setDialogAuthOpen(false);
        setGradeLoading(false);
    };

    const saveGrade = (value, taskQuestionResultId = null, gradeBlockIndex = 0) => {
        let questionResultId = currentQuestion.id;
        const currentTask = returnCurrentTask(currentQuestion);
        if (currentTask) {
            questionResultId = currentTask.questionResults[gradeBlockIndex].id;
        }
        setGradeLoading(taskQuestionResultId || true);

        if (propPublic && !localStorage.getItem('token')) {
            openAuthorisationDialog(value);
            return;
        }

        return gradeFunction(questionResultId, value)
            .then(({ success, data }) => {
                setGradeLoading(false);
                if (success) {
                    addGradeEvent(value, gradeBlockIndex);
                    setupGuideStore.updateGradeInfo();
                    if (updateUserAuditionAndBox) updateUserAuditionAndBox(candidateId);
                    if (currentTask) {
                        setQuestionResultIndex(gradeBlockIndex);
                        const newCurrentQuestion = { ...currentQuestion };
                        newCurrentQuestion.candidateTasks[taskNumber].questionResults[gradeBlockIndex] = data;
                        setCurrentQuestion(newCurrentQuestion);
                    } else {
                        setGradeQuestion(data);
                        updateInQuestionsList(questionResultId, data);
                    }
                }
            })
            .catch((err) => {
                setGradeLoading(false);
                flashMessage(err?.response?.status === 403
                    ? 'You don’t have permission for this action.'
                    : 'Something went wrong',
                'error');
            });
    };

    const updateInQuestionsList = (questionResultId, data) => {
        const foundIndex = questionResults.findIndex(({ id }) => id === questionResultId);
        if (foundIndex === -1) return;
        const newQuestionResults = [...questionResults];
        newQuestionResults[foundIndex].manualGradeMissing = data.manualGradeMissing;
        setQuestionResults(newQuestionResults);
    };

    const addGradeEvent = (scoreGiven, questionIndex) => {
        if (!currentQuestion) return;
        const { question: { uuid: questionId } } = currentQuestion;
        const currentTask = returnCurrentTask(currentQuestion);
        GeneralEvents.QUESTION_SCORED({
            scoreGiven,
            candidateId: candidateUuid,
            questionNumber: questionNumber + 1,
            questionId,
            taskId: currentTask?.questionResults[questionIndex].question.uuid,
            ttId,
            ttName,
            context: 'grading assessment candidate card'
        });
    };

    const canGrade = checkHasUserPermission(company, permissions, 'grade');

    if (isLoading) {
        return (
            <Typography className="u-mrg--bx2" variant="h5">
                <span>Candidate Answers</span>
                <CircularProgress color="primary" className="u-mrg--lx2" size={20} />
            </Typography>
        );
    }

    if (!questionResults.length && !screenQuestionResults.length) return null;

    const commonProps = {
        candidate,
        audition,
        updateUserAuditionAndBox,
        gradeableCard,
        propPublic,
        gotoQuestion,
        isIntegration,
        saveGrade,
        gradeLoading,
        canGrade,
        currentGrade,
        currentQuestion,
        fullName,
        questionResults,
        questionNumber,
        setGradeQuestion,
        setGradeLoading
    };

    return (
        <>
            <Typography className="u-mrg--bx2" variant="h5">Candidate Answers</Typography>
            <div className={clsx(classes.wrapper, showTasks && classes.wrapperExtented)}>
                <GradeCardNavigation
                    questions={questionResults}
                    questionNumber={questionNumber}
                    gotoQuestion={gotoQuestion}
                    propPublic={propPublic}
                    screenSelected={screenSelected}
                    setScreenSelected={setScreenSelected}
                    hasScreenQuestions={Boolean(screenQuestionResults.length)}
                    company={company}
                />
                {
                    screenSelected ? (
                        <ScreenList
                            screenQuestionResults={screenQuestionResults}
                        />
                    ) : (
                        <>
                            {
                                showTasks ? (
                                    <TasksGradeBlock
                                        taskNumber={taskNumber}
                                        setTaskNumber={setTaskNumber}
                                        {...commonProps}
                                    />
                                ) : (
                                    <SimpleGradeBlock {...commonProps} />
                                )
                            }
                        </>
                    )
                }
            </div>
            <AuthDialog
                open={dialogAuthOpen}
                notSavedValue={unSavedGrade}
                saveValue={saveGrade}
                closeDialog={closeAuthorisationDialog}
                candidateName={fullName}
                flashMessage={flashMessage}
                authorizeGuest={authorizeGuest}
            />
        </>
    );
});

export default GradeBlock;
