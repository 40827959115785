import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { getCompanySet, getListOfCompanySets, addSetToAssessment } from 'requests/CMSRequests';
import TruncateWithTooltip from 'components/tooltips/TruncateWithTooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { appCtx } from 'components/appStore';
import QuestionsList from '../../../../common/QuestionsList';
import EmptyQuestionsList from '../../../../common/EmptyQuestionsList';
import SetPaginator from '../../../../common/SetPaginator';
import EmptySetsList from './EmptySetsList';


const styles = () => ({
    selectedSkillWrapper: {
        marginRight: 30,
        whiteSpace: 'pre-wrap'
    },
    addButton: {
        height: 42,
        width: 90,
        marginLeft: 12
    }
});

const QuestionSetContent = observer(({
    classes, match, openDrawer, selectedSkill, getAssessment, setSelectedSet, ...other
}) => {
    const [selectedSetData, setSelectedSetData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingAdding, setLoadingAdding] = useState(false);
    const [sets, setSets] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { flashMessage } = useContext(appCtx);

    const selectedSet = sets[selectedIndex];
    const { auditionUuid } = match.params;

    useEffect(() => {
        if (openDrawer) {
            getListOfCompanySets(selectedSkill ? selectedSkill.id : null)
                .then(({ success, data }) => {
                    if (success && data) {
                        setSets(data);
                        setSelectedIndex(0);
                    }
                });
        } else {
            setSets([]);
            setSelectedSetData(null);
            setSelectedIndex(0);
        }
    }, [openDrawer]);

    useEffect(() => {
        setSelectedSetData(null);
        if (!selectedSet) return;
        setLoading(true);
        loadCurrentSetData();
    }, [selectedSet]);

    const onNext = () => {
        const newIndex = selectedIndex === sets.length - 1 ? 0 : selectedIndex + 1;
        setSelectedIndex(newIndex);
    };

    const onPrev = () => {
        const newIndex = !selectedIndex ? sets.length - 1 : selectedIndex - 1;
        setSelectedIndex(newIndex);
    };

    const addSet = () => {
        setLoadingAdding(true);
        addSetToAssessment(auditionUuid, selectedSet.uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    setSelectedSet(selectedSetData);
                    getAssessment();
                    flashMessage('Question set added to assessment', 'done');
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    flashMessage(err.response.data.msg, 'error');
                }
            })
            .finally(() => {
                setLoadingAdding(false);
            });
    };

    const loadCurrentSetData = async () => {
        if (!selectedSet) return;
        return getCompanySet(selectedSet.uuid)
            .then(({ success, data }) => {
                if (success && data) {
                    setSelectedSetData(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const questions = selectedSetData ? selectedSetData.companyQuestions : null;

    return (
        <>
            <div className="u-dsp--distribute">
                <div className={classes.selectedSkillWrapper}>
                    {
                        selectedSetData && (
                            <Typography variant="h6">
                                <TruncateWithTooltip capitalize text={selectedSetData.title} width={180} />
                            </Typography>
                        )
                    }
                </div>
                <div className="u-dsp--centered">
                    {
                        Boolean(sets.length) && (
                            <>
                                <Typography
                                    variant="h6"
                                    className="u-mrg--rx2 u-txt--nowrap"
                                >
                                    Question sets
                                </Typography>
                                <SetPaginator
                                    selectedIndex={selectedIndex}
                                    totalNumber={sets.length}
                                    onNext={onNext}
                                    onPrev={onPrev}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={loadingAdding}
                                    onClick={addSet}
                                    className={classes.addButton}
                                >
                                    {
                                        loadingAdding ? (
                                            <CircularProgress size={22} />
                                        ) : <span>Add</span>
                                    }
                                </Button>
                            </>
                        )
                    }
                </div>
            </div>
            <div>
                {
                    !sets.length && (
                        <EmptySetsList />
                    )
                }
                <QuestionsList
                    hideDuplicate
                    hideAdd
                    hideEdit
                    hideReorder
                    loading={loading}
                    selectedSkill={selectedSkill}
                    selectedSet={selectedSet}
                    loadQuestions={loadCurrentSetData}
                    questions={questions}
                    {...other}
                />
                {
                    !loading && (!questions || !questions.length) && selectedSet && (
                        <EmptyQuestionsList />
                    )
                }
            </div>
        </>
    );
});

export default withRouter(withStyles(styles)(QuestionSetContent));
