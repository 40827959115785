import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import CandidateIcon from './icon.svg';

import styles from '../QuestionAddedToAssessmentDialog/styles';


const ReplaceQuestionDialog = ({
    open, classes, onClose,
    replaceQuestionWithSaved,
    loading
}) => {
    const handleEditQuestion = () => {
        replaceQuestionWithSaved();
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
            handleClose={onClose}
            contentClassName={classes.contentClassName}
            actionComponent={(
                    <>
                        <Button
                            className={classes.button}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.duplicateButton}
                            color="primary"
                            variant="contained"
                            disabled={loading}
                            onClick={handleEditQuestion}
                        >
                            {
                                loading ? (
                                    <CircularProgress
                                        className={classes.loader}
                                        size={25}
                                    />
                                ) : <span>Replace Question</span>
                            }
                        </Button>
                    </>
            )}
        >
            <img src={CandidateIcon} alt="!" className="u-mrg--bx2" />
            <div className={classes.contentTitle}>
                Are you sure you want to replace <br />
                an existing question?
            </div>
            <div className={classes.contentText}>
                You will not be able to undo this action.
            </div>
        </Dialog>
    );
};

export default withStyles(styles)(ReplaceQuestionDialog);
