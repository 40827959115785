import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import TextInputOutlined from 'libraries/TextInput';
import CircularProgress from '@mui/material/CircularProgress';

const RenameDialog = observer(({ open, privateAssessments, onClose, id, name = '', renameFolder }) => {
    const [newName, setNewName] = useState(name);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setNewName(e.target.value);
    };

    useEffect(() => {
        if (name.length) setNewName(name);
    }, [name]);

    const changeFolderName = () => {
        setLoading(true);
        if (!renameFolder) return;
        renameFolder(id, newName)
            .then(() => {
                onClose();
            })
            .finally(() => setLoading(false));
    };

    const label = privateAssessments ? 'Folder' : 'Category';

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            handleClose={onClose}
            titleComponent={`Edit ${label} Name`}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={() => {
                            onClose();
                            setTimeout(() => setNewName(name), 200);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        variant="contained"
                        disabled={newName.length < 1 || loading}
                        onClick={changeFolderName}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={22}
                                    thickness={3}
                                />
                            ) : <span>Save</span>
                        }
                    </Button>
                </>
            )}
        >
            <TextInputOutlined
                label={`${label} name`}
                variant="outlined"
                maxLength={privateAssessments ? 150 : 255}
                fullWidth
                value={newName}
                onChange={handleChange}
            />
        </Dialog>
    );
});

export default RenameDialog;
