import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Select from 'libraries/Select';
import { appCtx } from 'components/appStore';
import { changeTeamMemberRoleByAssessment } from 'requests/ScriptRequests';

import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles';

const InviteBlock = observer(({ notInvitedList, loadNotInvitedList, opportunitySlug, onAddTeamMember, classes }) => {
    const [selectedMember, setSelectedMember] = useState('');
    const [selectedRole, setSelectedRole] = useState('viewer');
    const [loading, setLoading] = useState(false);
    const { flashMessage } = useContext(appCtx);

    const sendInvitation = () => {
        setLoading(true);
        changeTeamMemberRoleByAssessment(opportunitySlug, selectedMember, selectedRole)
            .then(({ success, data }) => {
                if (success) {
                    if (onAddTeamMember) onAddTeamMember({ ...data, selectedMember, roleType: selectedRole });
                    setSelectedRole('viewer');
                    setSelectedMember('');
                    loadNotInvitedList();
                    flashMessage('Team member invited.', 'done');
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                flashMessage(err.response.data && err.response.data.errors ? err.response.data.errors : 'Something went wrong');
            });
    };

    const handleSelectedRole = (e) => {
        const { value } = e.target;
        setSelectedRole(value);
    };

    const handleSelectedMember = (e) => {
        const { value } = e.target;
        setSelectedMember(value);
    };

    return (
        <div className={classes.actionsWrapper}>
            <Select
                variant="outlined"
                margin="none"
                value={selectedMember}
                disabled={!notInvitedList.length}
                label="Add Team members"
                onChange={handleSelectedMember}
                className={classes.memberSelect}
                options={notInvitedList.map(({ uuid, fullName }) => ({ value: uuid, label: fullName }))}
            />
            <Select
                className={classes.roleSelect}
                margin="none"
                value={selectedRole}
                onChange={handleSelectedRole}
                variant="outlined"
                options={[{
                    label: 'Viewer',
                    value: 'viewer'
                }, {
                    label: 'Editor',
                    value: 'admin'
                }]}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={sendInvitation}
                className={classes.sendButton}
                disabled={!selectedRole || !selectedMember || loading}
            >
                {
                    loading ? (
                        <CircularProgress
                            size={20}
                            className={classes.loader}
                        />
                    ) : <span>Add</span>
                }
            </Button>
        </div>
    );
});

export default withStyles(styles)(InviteBlock);
