import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from 'libraries/Dialog';
import CircularProgress from '@mui/material/CircularProgress';


const useStyles = makeStyles(() => ({
    addButton: {
        minWidth: 140
    },
    loader: {
        color: '#fff'
    }
}));

const ActionDialog = ({ open, titleComponent, children, onClose, buttonLabel, onClick, disabledButton, isLoading }) => {
    const { addButton, loader } = useStyles();

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            titleComponent={titleComponent}
            handleClose={onClose}
            onClose={onClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={addButton}
                        variant="contained"
                        color="primary"
                        onClick={onClick}
                        disabled={disabledButton || isLoading}
                    >
                        {
                            isLoading ? (
                                <CircularProgress size={23} className={loader} />
                            ) : <span>{buttonLabel}</span>
                        }
                    </Button>
                </>
            )}
        >
            {children}
        </Dialog>
    );
};

export default ActionDialog;
