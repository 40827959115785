import React from 'react';
import uuidv4 from 'uuid/v4';

import { withStyles } from '@mui/styles';

import { AUDIO, VIDEO, ANY_FILE, IMAGE, CODE_TEST } from 'helper/constants';
import FormGroup from '@mui/material/FormGroup';
import NoInteractionTooltip from '../NoInteractionTooltip';
import styles from './styles';
import MediaAnswerOption from './components/MediaAnswerOption';

const MultiChoice = ({
    answers = [], classes, validAnswers = answers, checkbox,
    hideTooltip, question: { uuid, id }, brandColor,
    isOrderedMultipleChoice
}) => {
    if (!validAnswers.length) return null;

    if (validAnswers.find(answer => [AUDIO, VIDEO, ANY_FILE, IMAGE, CODE_TEST].some(type => type === answer.type))) {
        return (
            <div className={classes.wrapper}>
                <FormGroup
                    classes={{ root: classes.optionsWrapper }}
                >
                    {
                        validAnswers.map((answer, index) => (
                            <MediaAnswerOption
                                brandColor={brandColor}
                                answer={answer}
                                key={answer.id || uuidv4()}
                                index={index}
                                checkbox={checkbox}
                                isOrderedMultipleChoice={isOrderedMultipleChoice}
                            />
                        ))
                    }
                </FormGroup>
            </div>
        );
    }

    return <>
        <ul
            className={classes.listWrapper}
            data-tip
            data-for={`non_interactive_preview_multiple_choice_${uuid || id}`}
        >
            {
                validAnswers.map(({ title }, index) => (

                    <li
                        key={index}
                        className={classes.listItem}
                    >
                        {
                            isOrderedMultipleChoice ? (
                                <>{index + 1}. &nbsp;</>
                            ) : (
                                <span
                                    style={{
                                        borderRadius: checkbox ? '3px' : '50%',
                                        borderColor: brandColor
                                    }}
                                />
                            )
                        }
                        <div className={classes.label}>
                            {title}
                        </div>
                    </li>
                ))
            }
        </ul>
        {
            !hideTooltip && (<NoInteractionTooltip id={`non_interactive_preview_multiple_choice_${uuid || id}`} />)
        }
    </>;
};


export default withStyles(styles)(MultiChoice);
