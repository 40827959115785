import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import PreviewMessageDialog from 'components/dialogs/PreviewMessageDialog';

import CircularProgress from '@mui/material/CircularProgress';
import {
    changeAssessmentRejectionEmailData, deleteAssessmentRejectionEmailData, getAssessmentAdmins,
    getAssessmentRejectionEmailData
} from 'requests/EmailRequests';
import { appStore } from 'components/appStore';
import styles from './styles';


const PreviewRejectionEmailButton = ({ classes, audition }) => {
    const [rejectionEmailDefaultData, setRejectionEmailDefaultData] = useState({});
    const [openPreviewRejectionEmail, setOpenPreviewRejectionEmail] = useState(false);
    const [replyToOptions, setReplyToOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { slug, brand } = audition;

    useEffect(() => {
        if (!slug) return;
        if (!openPreviewRejectionEmail) {
            setDefaultEmailSettings();
            setReplyToOptions([]);
            return;
        }
        setLoading(true);
        getAssessmentRejectionEmailData(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setData(data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        getAssessmentAdmins(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setReplyToOptions(data);
                }
            });
    }, [openPreviewRejectionEmail, slug]);

    const saveSettings = data => changeAssessmentRejectionEmailData(slug, { ...rejectionEmailDefaultData, ...data })
        .then(({ success, data: newData }) => {
            if (success && newData) {
                setData(newData);
            }
            return { success, errorForm: {} };
        });

    const setData = (data) => {
        setRejectionEmailDefaultData({ ...data, replyTo: data.replyTo && data.replyTo.id });
    };

    const handleRejectionPreview = () => {
        setOpenPreviewRejectionEmail(!openPreviewRejectionEmail);
    };

    const handleChangeSettingsParam = (newValue) => {
        setRejectionEmailDefaultData({ ...rejectionEmailDefaultData, ...newValue });
    };

    const resetSettings = () => deleteAssessmentRejectionEmailData(slug)
        .then(() => {
            setDefaultEmailSettings();
        });


    const setDefaultEmailSettings = () => {
        setRejectionEmailDefaultData({
            replyTo: appStore.loggedUser.id
        });
    };

    return (
        <>
            <Button
                color="primary"
                disabled={loading}
                onClick={handleRejectionPreview}
                className={classes.actionButton}
            >
                {
                    loading ? (
                        <CircularProgress size={20} />
                    ) : <span>Preview and edit rejection email</span>
                }
            </Button>
            {
                !loading && (
                    <PreviewMessageDialog
                        {...rejectionEmailDefaultData}
                        open={openPreviewRejectionEmail}
                        type="rejection"
                        isFirstProgressColumn
                        replyToOptions={replyToOptions}
                        opportunity={{
                            uuid: audition && audition.uuid,
                            jobTitle: audition && audition.name
                        }}
                        columnType="progress"
                        handleClose={handleRejectionPreview}
                        handleChangeSettingsParam={handleChangeSettingsParam}
                        onSave={saveSettings}
                        onReset={resetSettings}
                        assessmentBrand={brand}
                    />
                )
            }
        </>
    );
};

export default withStyles(styles)(PreviewRejectionEmailButton);
