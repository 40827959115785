import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import { unrejectCandidate } from 'requests/JobOpportunityRequests';
import GeneralEvents from 'events/GeneralEvents';
import { SCORE_TYPE_AI } from 'helper/constants';


const useStyles = makeStyles(() => ({
    addButton: {
        minWidth: 140
    },
    loader: {
        color: '#fff'
    }
}));

const UnrejectDialog = ({ open, user, dataForEvent, flashMessage, handleClose, callback }) => {
    const { addButton, loader } = useStyles();

    const [loading, setLoading] = useState(false);

    const { score: scoreObj, uuid, fraudDetected, plagiarismDetected, source } = user;

    const rejectUser = async () => {
        setLoading(true);
        unrejectCandidate(user.columnUserUuid)
            .then((response) => {
                setLoading(false);
                if (response.success) {
                    dataForEvent.candidateUUID = uuid;
                    dataForEvent.multipleGeoIpDetected = fraudDetected;
                    dataForEvent.plagiarismDetected = plagiarismDetected;
                    dataForEvent.ttUUID = dataForEvent.ttId;
                    dataForEvent.scoreIsAI = scoreObj ? scoreObj.type === SCORE_TYPE_AI : false;
                    delete dataForEvent.ttId;
                    delete dataForEvent.candidateId;
                    delete dataForEvent.candidateHired;
                    delete dataForEvent.rank;
                    GeneralEvents.CANDIDATE_UNREJECTED(dataForEvent);
                    flashMessage('Un-rejected successfully', 'done');
                }
                if (callback) {
                    callback(response);
                }
                if (response.success) handleClose();
            })
            .catch(() => {
                flashMessage('Something went wrong', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <Dialog
            open={open}
            maxWidth="sm"
            titleComponent="Are you sure you want to un-reject the candidate?"
            handleClose={handleClose}
            onClose={handleClose}
            actionComponent={(
                <>
                    <Button
                        color="primary"
                        className="u-txt--bold"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={addButton}
                        variant="contained"
                        color="primary"
                        onClick={rejectUser}
                        disabled={loading}
                    >
                        {
                            loading ? (
                                <CircularProgress size={23} className={loader} />
                            ) : <span>Un-Reject Candidate</span>
                        }
                    </Button>
                </>
            )}
        >
            No email notifications will be sent to the candidate. Their status will only be updated in Vervoe. {source === 'JobAdder' && 'Please note it will not be reflected in your integration.'}
        </Dialog>
    );
};

export default UnrejectDialog;
