import React from 'react';
import { withStyles } from '@mui/styles';
import AlertIcon from 'img/alert.svg';
import { withRouter, NavLink } from 'react-router-dom';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '5px 0 25px',
        fontSize: 14,
        '& img': {
            marginRight: 10,
            height: 25
        },
        '& a': {
            color: theme.palette.yellow[800],
            marginLeft: 4
        }
    },
    warningText: {
        display: 'inline',
        fontWeight: 700
    }
});

const AutoProgressWarning = ({ classes, audition, location }) => {
    const { slug, auditionLinkEnabled, active } = audition;
    if (!auditionLinkEnabled || !active) return null;

    return (
        <div className={classes.wrapper}>
            <img src={AlertIcon} alt="!" />
            <div className={classes.warningText}>
                Auto progress is enabled. Some question types are not available. <NavLink to={{ pathname: `/script/settings/${slug}`, state: { from: location.pathname } }}>Auto progress settings</NavLink>
            </div>
        </div>
    );
};

export default withRouter(withStyles(styles)(AutoProgressWarning));
