import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PlusIcon from 'img/add.svg';
import PlusIconGrey from 'img/add_grey.svg';
import { appCtx } from 'components/appStore';
import UpgradeToUnlockTooltip from 'components/tooltips/UpgradeToUnlockTooltip';
import AssessmentEvents from 'events/AssessmentEvents';
import { ACCESS_PREMIUM_CONTENT, TYPING_TEST } from 'helper/constants';
import { checkHasCompanyFeature } from 'helper/commonFunctions';

import QuestionAddedToAssessmentDialog from '../../QuestionBanks/components/QuestionAddedToAssessmentDialog';
import ReplaceQuestionDialog from '../../QuestionBanks/components/ReplaceQuestionDialog';

import styles from './styles';

const AddToAssessmentButton = observer(({
    classes, question, activeQuestion,
    updateQuestion, setActiveTab, selectQuestion,
    activeQuestionIndex, replaceFunc,
    questions, loading, setLoading, segmentData,
    assessmentHasTypingTest, isSimulationList, onReplace
}) => {
    const [questionAddedDialogOpen, setQuestionAddedDialogOpen] = useState(false);
    const [replaceQuestionDialogOpen, setReplaceQuestionDialogOpen] = useState(false);
    const [newQuestionData, setNewQuestionData] = useState(null);

    const { flashMessage, company } = useContext(appCtx);
    const { template, screeningQuestion, valid } = activeQuestion;

    const premiumContentBlocked = !checkHasCompanyFeature(company, ACCESS_PREMIUM_CONTENT) && question.premiumContent;

    const disabled = assessmentHasTypingTest && question.answerType === TYPING_TEST;

    const handleClick = () => {
        if (template || isSimulationList || (screeningQuestion && !valid)) {
            replaceQuestionWithSaved();
        } else {
            setReplaceQuestionDialogOpen(true);
        }
    };

    const replaceQuestionWithSaved = () => {
        setLoading(true);
        replaceFunc(question)
            .then(({ data, success }) => {
                if (data && success) {
                    setNewQuestionData(data);
                    setQuestionAddedDialogOpen(true);
                    if (segmentData.questionSuggested) {
                        segmentData.questionSaved = false;
                        segmentData.questionNumber = activeQuestionIndex + 1;
                    }
                    if (onReplace) onReplace();
                    if (screeningQuestion) {
                        AssessmentEvents.SCREENER_QUESTION_ADDED(segmentData);
                    } else if (segmentData.questionSuggested) {
                        AssessmentEvents.QUESTION_SUGGESTED_ADDED(segmentData);
                    } else {
                        AssessmentEvents.QUESTION_ADDED(segmentData);
                    }
                }
                setReplaceQuestionDialogOpen(false);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                flashMessage('Something went wrong', 'error');
            });
    };

    const onCloseConfirmationModal = () => {
        if (!isSimulationList) {
            updateQuestion(newQuestionData);
        }
        setQuestionAddedDialogOpen(false);
    };

    if (premiumContentBlocked) {
        return (
            <UpgradeToUnlockTooltip>
                <span>
                    <Button
                        className={classes.addButton}
                        disabled
                    >
                        <img
                            className="u-mrg--rx1"
                            src={PlusIconGrey}
                            alt="+"
                        />
                    Add
                    </Button>
                </span>
            </UpgradeToUnlockTooltip>
        );
    }

    return (
        <>
            <Button
                className={classes.addButton}
                onClick={handleClick}
                disabled={loading || disabled}
            >
                {
                    loading
                        ? <CircularProgress size={20} />
                        : <>
                            <img
                                className="u-mrg--rx1"
                                src={disabled ? PlusIconGrey : PlusIcon}
                                alt="+"
                            />
                            <span>Add</span>
                        </>
                }
            </Button>
            <ReplaceQuestionDialog
                loading={loading}
                open={replaceQuestionDialogOpen}
                replaceQuestionWithSaved={replaceQuestionWithSaved}
                onClose={() => {
                    setReplaceQuestionDialogOpen(false);
                }}
            />
            <QuestionAddedToAssessmentDialog
                open={questionAddedDialogOpen}
                onClose={onCloseConfirmationModal}
                {...{
                    setActiveTab,
                    activeQuestion,
                    questions,
                    selectQuestion,
                    isSimulationList,
                    activeQuestionIndex,
                    updateQuestion,
                    newQuestionData
                }}
            />
        </>
    );
});

export default withStyles(styles)(AddToAssessmentButton);
