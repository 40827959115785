import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import Dialog from 'libraries/Dialog';
import TextInputOutlined from 'libraries/TextInput';
import { updateSet } from 'requests/CMSRequests';
import CircularProgress from '@mui/material/CircularProgress';
import { appCtx } from 'components/appStore';

const EditSetDialog = observer(({ onSuccess, set, openedDialog, handleDialog }) => {
    const [loading, setLoading] = useState(false);
    const [skillName, setSkillName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { flashMessage } = useContext(appCtx);

    useEffect(() => {
        setSkillName(set ? set.title : '');
        setErrorMessage('');
    }, [set]);


    const handleSkillName = (e) => {
        setSkillName(e.target.value);
        setErrorMessage('');
    };

    const submit = () => {
        setLoading(true);
        updateSet(set.uuid, skillName)
            .then(({ success, data }) => {
                if (success && data) {
                    handleDialog();
                    flashMessage('Set updated', 'done');
                    if (onSuccess) onSuccess(data);
                }
            })
            .catch((err) => {
                if (err.response && err.response.data.msg) {
                    setErrorMessage(err.response.data.msg);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            onClose={handleDialog}
            handleClose={handleDialog}
            titleComponent="Edit Set"
            maxWidth="sm"
            actionComponent={(
                <>
                    <Button
                        className="u-txt--bold"
                        onClick={handleDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="u-pdn--rx3 u-pdn--lx3"
                        disabled={!skillName || !skillName.trim() || loading}
                        onClick={submit}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                />
                            ) : <span>Save</span>
                        }
                    </Button>
                </>
            )}
            open={openedDialog}
        >

            <TextInputOutlined
                onChange={handleSkillName}
                value={skillName}
                variant="outlined"
                maxLength={255}
                minLength={1}
                hasCounter
                placeholder="Enter your new set name"
                label="Set Name"
                isError={Boolean(errorMessage)}
                helperText={errorMessage}
            />
        </Dialog>
    );
});

export default EditSetDialog;
