import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import { pauseSubscription } from 'requests/SubscriptionRequests';


import { appCtx } from 'components/appStore';

import styles from './styles';

const SubscriptionInfoBlock = observer(({
    classes, pauseSubscriptionDialogOpen,
    handlePauseSubscriptionDialog, getCompanyPlan
}) => {
    const [loading, setLoading] = useState(false);
    const { company, flashMessage, updateCompany } = useContext(appCtx);


    const handlePause = () => {
        setLoading(true);
        pauseSubscription()
            .then(({ success }) => {
                if (success) {
                    getCompanyPlan();
                    flashMessage('Your account subscription has been paused.');
                    updateCompany({ ...company, isSubscriptionPaused: true });
                    handlePauseSubscriptionDialog();
                }
            })
            .catch(({ response: { data } }) => {
                if (data.errors) {
                    flashMessage(data.errors.message);
                } else {
                    flashMessage('Something went wrong.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            titleComponent="Are you sure you want to pause your subscription?"
            handleClose={handlePauseSubscriptionDialog}
            onClose={handlePauseSubscriptionDialog}
            open={pauseSubscriptionDialogOpen}
            actionComponent={(
                <>
                    <Button
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        onClick={handlePauseSubscriptionDialog}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={loading}
                        onClick={handlePause}
                        className={classes.pauseDialogButton}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : <span>Pause Subscription</span>
                        }
                    </Button>
                </>
            )}
        >
            After <b>3 months</b> your account will be automatically unpaused.
            <br /><br />
            The access to your candidates, assessments and data will be locked, however you can unpause at any time.
        </Dialog>
    );
});

export default withStyles(styles)(SubscriptionInfoBlock);
