import React from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import AuthEvents from 'events/AuthEvents';
import RegistrationWrapper from './RegistrationWrapper';

import IEBanner from '../../../components/layout/IEBanner';

class Registration extends React.Component {
    constructor(props) {
        super(props);
        const { location } = props;
        const queryParams = queryString.parse(location.search);
        this.teamMemberLink = Boolean(queryParams.email);
    }

    componentDidMount() {
        const { location } = this.props;
        const queryParams = queryString.parse(location.search);

        if (queryParams.company) {
            localStorage.setItem('onBoardingCompany', queryParams.company);
        }
        if (queryParams.planToHire) {
            localStorage.setItem('onBoardingPlanToHire', queryParams.planToHire);
        }

        AuthEvents.VIEWED_PAGE();
        AuthEvents.EMPLOYER_REGISTRATION_VIEWED();
    }

    render() {
        const { location, flashMessage, isTrial } = this.props;

        const isIE = !!document.documentMode;
        if (isIE) {
            return <IEBanner />;
        }

        const localStorageToken = localStorage.getItem('token');
        if (localStorageToken) {
            return <Redirect to="/welcome" />;
        }

        return (
            <>
                <Helmet>
                    <title>Create Your Free Vervoe Account Online Now</title>
                    <meta name="description" content="Vervoe is an intelligent hiring platform that uncovers hidden talent through Skills Assessments designed by leading industry experts. Vervoe uses machine learning to automatically rank candidates based on how they perform in real-world scenarios." />
                </Helmet>
                <RegistrationWrapper
                    location={location}
                    flashMessage={flashMessage}
                    teamMemberLink={this.teamMemberLink}
                    isTrial={isTrial}
                    buttonLabel={
                        !this.teamMemberLink
                            ? <span>Create account</span>
                            : <span>Join the team</span>
                    }
                />
            </>
        );
    }
}

export default Registration;
