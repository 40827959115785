import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withTheme, withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';

import Tooltip from 'libraries/Tooltip';

import BookmarkEmptyIcon from 'components/icons/BookmarkEmptyIcon';
import BookmarkIcon from 'components/icons/BookmarkIcon';
import { appCtx } from 'components/appStore';
import ShortListDialog from 'components/dialogs/ShortListDialog';
import { shortlistCandidate } from 'requests/CandidatesRequests';
import AssessmentEvents from 'events/AssessmentEvents';

import { statsCtx } from '../../../../statsStore';
import { candidateResultCtx } from '../../CandidateListWrapper/candidatesStore';

import styles from './styles';


const ShortlistButton = observer(({
    classes, theme, candidate, onAdd, shortlisted,
    onRemove, dataForEvents, ...other
}) => {
    const [open, setOpen] = useState(false);
    const [isActive, setIsActive] = useState(shortlisted);
    const { flashMessage, loggedUser } = useContext(appCtx);
    const { increaseShortlistCandidatesCount } = useContext(statsCtx);
    const { refreshCandidate } = useContext(candidateResultCtx);
    const { userAuditionId, shortlistedAt, shortlistedBy } = candidate;

    const onClick = async (e) => {
        e.stopPropagation();
        const newValue = !isActive;

        if (!newValue) {
            handleDialog();
        } else {
            setIsActive(newValue);
            const { data: shortlistedCandidate } = await shortlistCandidate(userAuditionId, newValue);
            const { shortlistedBy: eventShortlistedBy, shortlistedAt: eventShortlistedAt } = shortlistedCandidate;
            dataForEvents.shortlistedBy = eventShortlistedBy && eventShortlistedBy.id;
            dataForEvents.shortlistedDate = eventShortlistedAt;
            AssessmentEvents.CANDIDATE_SHORTLISTED(dataForEvents);
            if (onAdd) onAdd();
            refreshCandidate(shortlistedCandidate);
            flashMessage('Candidate Shortlisted', 'done');
            increaseShortlistCandidatesCount();
        }
    };

    const handleDialog = () => {
        setOpen(!open);
    };

    const removeFromShortlist = async () => {
        handleDialog();
        setIsActive(false);
        await shortlistCandidate(userAuditionId, false);
        dataForEvents.shortlistedBy = shortlistedBy && shortlistedBy.id;
        dataForEvents.shortlistedDate = shortlistedAt;
        dataForEvents.shortlistedRemovedBy = loggedUser.id;
        dataForEvents.shortlistedRemovedDate = new Date();
        AssessmentEvents.CANDIDATE_SHORTLISTED_REMOVED(dataForEvents);
        if (onRemove) onRemove();
        flashMessage('Candidate removed from shortlist', 'done');
        increaseShortlistCandidatesCount(false);
    };

    return <>
        <Tooltip
            label={isActive ? <span>Remove from shortlist</span> : <span>Shortlist</span>}
        >
            <IconButton onClick={onClick} className={classes.iconStar} {...other} size="large">
                {
                    isActive ? (
                        <BookmarkIcon color={theme.palette.green[600]} />
                    ) : (
                        <BookmarkEmptyIcon color={theme.palette.grey[400]} />
                    )
                }
            </IconButton>
        </Tooltip>
        <ShortListDialog
            open={open}
            handleDialog={handleDialog}
            removeFromShortlist={removeFromShortlist}
        />
    </>;
});

export default withStyles(styles)(withTheme(ShortlistButton));
