import React from 'react';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


const ConfirmCancelPlanDialog = ({ open, handleConfirmCancelPlan, onClose, isLoadingConfirmCancelPlan }) => (
    <Dialog
        open={open}
        maxWidth="sm"
        onClose={onClose}
        handleClose={onClose}
        titleComponent="Switch Plans?"
        actionComponent={(
            <>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    label="Change Plan"
                    className="u-txt--bold u-pdn--rx6 u-pdn--lx6"
                    onClick={handleConfirmCancelPlan}
                    disabled={isLoadingConfirmCancelPlan}
                >
                    {
                        isLoadingConfirmCancelPlan ? (
                            <CircularProgress size={20} style={{ color: '#fff' }} />
                        ) : <span>Next</span>
                    }
                </Button>
            </>
        )}
    >
        Please note your current plan will end when you proceed with purchasing this candidate pack. This action cannot be undone, click next to confirm.
    </Dialog>
);

export default ConfirmCancelPlanDialog;
