import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from 'libraries/Dialog';
import Select from 'libraries/Select';

import styles from './styles';

const ReorderDialog = observer(({
    open, onClose, onReorder,
    entityIndex, classes, entityList,
    entity = 'question', labelParameter = 'description',
    hideIndex
}) => {
    const [position, setPosition] = useState('after');
    const [targetQuestionIndex, setTargetQuestionIndex] = useState(null);
    const [loading, setLoading] = useState(false);

    const handlePosition = (e) => {
        const { value } = e.target;
        setPosition(value);
    };

    const handleTargetQuestionIndex = (e) => {
        const { value } = e.target;
        setTargetQuestionIndex(value);
    };

    let newIndex = null;
    if (Number.isInteger(targetQuestionIndex)) {
        const isIndexLess = entityIndex <= targetQuestionIndex;
        if (position === 'after') {
            newIndex = isIndexLess ? targetQuestionIndex : targetQuestionIndex + 1;
        } else {
            newIndex = isIndexLess ? targetQuestionIndex - 1 : targetQuestionIndex;
        }
    }


    const questionOptions = [];

    entityList.forEach((item, index) => {
        if (entityIndex !== index) {
            const questionNumber = index + 1;
            questionOptions.push({
                value: index,
                label: !item[labelParameter]
                    ? <><b>{questionNumber}</b>. <span className="u-txt--cap">{entity}</span> {questionNumber}</>
                    : <><b>{questionNumber}</b>. {item[labelParameter]}</>,
                className: classes.nowrap
            });
        }
    });

    const handleReorder = async (e) => {
        if (entityIndex === newIndex) {
            handleClose(e);
            return;
        }
        setLoading(true);
        try {
            await onReorder(e, targetQuestionIndex, newIndex, position);
            handleClose(e);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleClose = (e) => {
        setPosition('after');
        setTargetQuestionIndex(null);
        onClose(e);
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            handleClose={handleClose}
            titleClassName="u-txt--cap"
            onClick={e => e.stopPropagation()}
            titleComponent={`Reorder ${entity} ${hideIndex ? '' : entityIndex + 1}`}
            actionComponent={(
                <>
                    <Button
                        className="u-txt--bold"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.reorderButton}
                        disabled={targetQuestionIndex === null || loading}
                        onClick={handleReorder}
                    >
                        {
                            loading ? (
                                <CircularProgress size={20} />
                            ) : <span>Reorder {entity}</span>
                        }
                    </Button>
                </>
            )}
        >
            Reorder this {entity} to
            <div className={classes.inputWrapper}>
                <Select
                    className={classes.positionSelect}
                    label="Position"
                    variant="outlined"
                    value={position}
                    onChange={handlePosition}
                    options={[
                        { value: 'after', label: 'After' },
                        { value: 'before', label: 'Before' }
                    ]}
                />
                <Select
                    label={entity}
                    variant="outlined"
                    menuPaperClassName={classes.menu}
                    placeholder={`Select a ${entity}`}
                    value={targetQuestionIndex}
                    onChange={handleTargetQuestionIndex}
                    options={questionOptions}
                    InputLabelProps={{ className: 'u-txt--cap' }}
                />
            </div>
        </Dialog>
    );
});

export default withStyles(styles)(ReorderDialog);
