import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

import PreviewMessageDialog from 'components/dialogs/PreviewMessageDialog';
import { appStore } from 'components/appStore';
import {
    getAssessmentAdmins,
    changeAssessmentEmailData,
    deleteAssessmentEmailData,
    getAssessmentInvitationEmailData
} from 'requests/EmailRequests';

import styles from './styles';

const PreviewInvitationEmailButton = ({
    classes, className, text = 'Preview invitation email', audition
}) => {
    const [openPreviewJobPageEmail, setOpenPreviewJobPageEmail] = useState(false);
    const [emailSettingsData, setEmailSettingsData] = useState({});
    const [replyToOptions, setReplyToOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { slug, brand } = audition;

    useEffect(() => {
        if (!slug) return;
        if (!openPreviewJobPageEmail) {
            setDefaultEmailSettings();
            setReplyToOptions([]);
            return;
        }
        setLoading(true);
        getAssessmentInvitationEmailData(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setData(data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        getAssessmentAdmins(slug)
            .then(({ success, data }) => {
                if (success && data) {
                    setReplyToOptions(data);
                }
            });
    }, [openPreviewJobPageEmail, slug]);

    const handleJobPagePreview = () => {
        setOpenPreviewJobPageEmail(!openPreviewJobPageEmail);
    };

    const handleChangeSettingsParam = (newValue) => {
        setEmailSettingsData({ ...emailSettingsData, ...newValue });
    };

    const saveSettings = (form) => {
        setEmailSettingsData(form);
        return changeAssessmentEmailData(slug, form)
            .then(({ success, data }) => {
                if (success && data) {
                    setData(data);
                }
                return { success, errorForm: {} };
            });
    };

    const setData = (data) => {
        setEmailSettingsData({ ...data, replyTo: data.replyTo && data.replyTo.id });
    };

    const resetSettings = () => deleteAssessmentEmailData(slug)
        .then(() => {
            setDefaultEmailSettings();
        });

    const setDefaultEmailSettings = () => {
        setEmailSettingsData({
            replyTo: appStore.loggedUser.id
        });
    };

    return (
        <>
            <Button
                color="primary"
                disabled={loading}
                onClick={handleJobPagePreview}
                className={clsx(classes.previewButton, className)}
            >
                {
                    loading ? (
                        <CircularProgress size={20} />
                    ) : <span>{text}</span>
                }
            </Button>
            {
                !loading && (
                    <PreviewMessageDialog
                        {...emailSettingsData}
                        open={openPreviewJobPageEmail}
                        type="invitation"
                        isFirstProgressColumn
                        replyToOptions={replyToOptions}
                        opportunity={{
                            uuid: audition && audition.uuid,
                            jobTitle: audition && audition.name,
                            deadline: audition && audition.daysToExpiration
                        }}
                        assessmentBrand={brand}
                        columnType="progress"
                        handleClose={handleJobPagePreview}
                        handleChangeSettingsParam={handleChangeSettingsParam}
                        onSave={saveSettings}
                        onReset={resetSettings}
                    />
                )
            }
        </>
    );
};

export default withStyles(styles)(PreviewInvitationEmailButton);
