import React, { useContext, useState } from 'react';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { unpauseSubscription } from 'requests/SubscriptionRequests';
import { appCtx } from 'components/appStore';

import Dialog from 'libraries/Dialog';

import styles from './styles';

const UnpauseButton = observer(({ classes, getCompanyPlan }) => {
    const [unpauseSubscriptionDialogOpen, setUnpauseBlockscriptionDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { flashMessage, updateCompany } = useContext(appCtx);

    const handleUnpauseBlockDialog = () => {
        setUnpauseBlockscriptionDialogOpen(!unpauseSubscriptionDialogOpen);
    };


    const handleUnpause = () => {
        setLoading(true);
        unpauseSubscription()
            .then(({ success, data }) => {
                if (success && data) {
                    getCompanyPlan();
                    flashMessage('Your account subscription has been unpaused.');
                    updateCompany(data);
                    handleUnpauseBlockDialog();
                }
            })
            .catch(({ response: { data } }) => {
                if (data.errors) {
                    flashMessage(data.errors.message);
                } else {
                    flashMessage('Something went wrong.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Button
                color="primary"
                className="u-txt--bold"
                variant="contained"
                onClick={handleUnpauseBlockDialog}
            >
                Unpause subscription
            </Button>
            <Dialog
                maxWidth="sm"
                titleComponent="Unpause your subscription?"
                handleClose={handleUnpauseBlockDialog}
                onClose={handleUnpauseBlockDialog}
                open={unpauseSubscriptionDialogOpen}
                actionComponent={(
                <>
                    <Button
                        className="u-txt--bold u-pdn--lx5 u-pdn--rx5"
                        onClick={handleUnpauseBlockDialog}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        onClick={handleUnpause}
                        className={classes.unpauseButton}
                    >
                        {
                            loading ? (
                                <CircularProgress
                                    size={24}
                                    thickness={3}
                                    classes={{ root: classes.loader }}
                                />
                            ) : <span>Unpause Subscription</span>
                        }
                    </Button>
                </>
                )}
            >
            By unpausing your account your billing cycling will resume from today. You will be able to invite candidates, create assessments and export data.
            </Dialog>
        </>
    );
});

export default withStyles(styles)(UnpauseButton);
