import React, { useState } from 'react';
import BoardItemMenu from 'components/board/board_item/board_item_menu';
import RemoveFromHiredDialog from 'components/dialogs/RemoveFromHiredDialog';
import RemoveIcon from 'components/candidate_cards/CandidateActions/CandidateMenu/remove.svg';

const CandidateRowMenu = ({ candidate, canEdit, callback }) => {
    const [removeHiredDialogOpen, setRemoveHiredDialogOpen] = useState(false);
    return <>
        <BoardItemMenu
            options={[{
                label: <><img src={RemoveIcon} alt="->" /> <span>Remove from Hired</span></>,
                onClick: () => setRemoveHiredDialogOpen(true),
                disabled: !canEdit
            }]}
        />
        <RemoveFromHiredDialog
            open={removeHiredDialogOpen}
            onClose={() => setRemoveHiredDialogOpen(false)}
            candidate={candidate}
            callback={callback}
        />
    </>;
};
export default CandidateRowMenu;
