import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@mui/styles';
import Dialog from 'libraries/Dialog';
import Select from 'libraries/Select';
import Button from '@mui/material/Button';
import TextInput from 'libraries/TextInput';
import { checkHasUserPermission } from 'helper/commonFunctions';
import RichTextEditor from 'react-rte';
import CircularProgress from '@mui/material/CircularProgress';
import MiscellaneousEvents from 'events/MiscellaneousEvents';
import MessageEditForm from './MessageEditForm';
import { appCtx, appStore } from '../../appStore';
import { defaultContent, defaultSubject } from './defaultScript';

import styles from './styles';

const TYPE_PROGRESS = 'progress';
const MAX_CHAR = 20000;


const PreviewMessageDialog = observer(({
    replyTo = null, opportunity, columnUuid,
    text = '', columnName, assessmentBrand,
    classes, handleClose, open, isFirstProgressColumn,
    type = 'invitation', columnType,
    handleChangeSettingsParam, onSave, onReset,
    replyToOptions = [], subject = defaultSubject(type, appStore.company)
}) => {
    const { company, flashMessage } = useContext(appCtx);
    const [form, setForm] = useState(null);
    const [errorForm, setError] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingReset, setLoadingReset] = useState(false);
    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        setDefaultForm();
    }, [text]);

    const { candidateSendFromEmail } = company;

    const eventData = ({
        ttUUID: columnUuid || opportunity.uuid,
        ttName: columnName || opportunity.jobTitle,
        emailType: type
    });

    const setDefaultForm = () => {
        const newForm = {};
        newForm.description = text || '';
        newForm.description = newForm.description
            ? RichTextEditor.createValueFromString(newForm.description, 'html')
            : RichTextEditor.createValueFromString(getDefaultContent(), 'html');
        const formMessage = newForm.description.toString('html');
        newForm.left = MAX_CHAR - formMessage.replace(/<(?:.|\n)*?>/gm, '').length;

        setInitialState({
            text: formMessage,
            subject,
            replyTo
        });

        setForm(newForm);
    };

    const getDialogTitle = () => {
        switch (type) {
            case 'reminder':
                return 'Candidate Reminder Message';
            case 'rejection':
                return 'Candidate Rejection Message';
            case 'feedback':
                return 'Candidate Feedback Message';
            default:
                return columnType === TYPE_PROGRESS
                    ? ('Candidate Invitation Message')
                    : ('Candidates who are not auto-progressed will receive the message you write in the box below.');
        }
    };

    const getDefaultContent = () => defaultContent(type, opportunity, company, isFirstProgressColumn, columnType, columnName);

    const getSubject = () => (typeof subject === 'string'
        ? subject : defaultSubject(type, company)
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoadingSave(true);
            const saveObj = {
                text: form.description.toString('html'),
                replyTo,
                subject: getSubject()
            };
            await onSave(saveObj);
            const { text: oldText, subject: oldSubject, replyTo: oldReplyTo } = initialState;
            MiscellaneousEvents.EMAIL_SETTINGS_UPDATED({
                ...eventData,
                ...saveObj,
                replyTo: saveObj.replyTo || 'no-reply',
                oldText,
                oldSubject,
                oldReplyTo: oldReplyTo || 'no-reply'
            });
            setInitialState(saveObj);
            setLoadingSave(false);
            flashMessage('Email message updated', 'done');
        } catch (err) {
            if (err.response && err.response.data.msg) {
                flashMessage(err.response.data.msg, 'error');
            }
            setLoadingSave(false);
        }
    };

    const handleSetDefault = async (e) => {
        e.preventDefault();
        const updateForm = { ...form, description: RichTextEditor.createValueFromString(getDefaultContent(), 'html') };
        const formMessage = updateForm.description.toString('html');
        updateForm.left = MAX_CHAR - formMessage.replace(/<(?:.|\n)*?>/gm, '').length;
        setForm(updateForm);
        try {
            setLoadingReset(true);
            await onReset();
            MiscellaneousEvents.EMAIL_SETTINGS_RESETED(eventData);
            setInitialState({
                replyTo: null,
                text: RichTextEditor.createValueFromString(getDefaultContent(), 'html').toString('html')
            });
            setLoadingReset(false);
            flashMessage('Email message updated', 'done');
        } catch (err) {
            setLoadingReset(false);
        }
    };

    const handleChangeSubject = (e) => {
        handleChangeSettingsParam({ subject: e.target.value });
    };

    const nothingChanged = () => {
        if (!form) return true;
        const { text: initialText, subject: initialSubject, replyTo: initialReplyTo } = initialState;
        return initialText === form.description.toString('html') && initialSubject === subject && initialReplyTo === replyTo;
    };

    const handleChangeReplyToEmail = (e) => {
        const { value } = e.target;
        handleChangeSettingsParam({ replyTo: value === 'no-reply' ? null : +value });
    };

    const { permissions } = opportunity;
    const edit = checkHasUserPermission(company, permissions, 'edit');
    const subjectText = getSubject();

    return (
        <Dialog
            onClose={handleClose}
            handleClose={handleClose}
            titleComponent={getDialogTitle()}
            actionComponent={(
                <div className={classes.dialogActions}>
                    <Button
                        color="primary"
                        onClick={handleSetDefault}
                        disabled={loadingReset}
                        className="u-txt--bold"
                    >
                        {
                            loadingReset ? (
                                <CircularProgress size={20} />
                            ) : <span>Reset</span>
                        }
                    </Button>
                    <div>
                        <Button
                            color="primary"
                            onClick={handleClose}
                            className="u-txt--bold u-mrg--rx2"
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="u-pdn--lx8 u-pdn--rx8 u-mrg--bx0"
                            onClick={handleSubmit}
                            disabled={nothingChanged() || (!subject && subject !== null) || form.left >= MAX_CHAR || loadingSave}
                        >
                            {
                                loadingSave ? (
                                    <CircularProgress className={classes.saveLoader} size={20} />
                                ) : <span>Save</span>
                            }
                        </Button>
                    </div>
                </div>
            )}
            open={open}
        >
            <p className={classes.replyLabel}>
                Emails will be sent from <b>{candidateSendFromEmail}</b> but candidate replies sent to your email
            </p>
            <Select
                value={replyTo || 'no-reply'}
                label="Reply to email"
                onChange={handleChangeReplyToEmail}
                fullWidth
                className="u-mrg--bx5"
                margin="none"
                variant="outlined"
                disabled={!edit}
                options={[
                    { value: 'no-reply', label: 'no-reply' }
                ].concat(
                    replyToOptions.map(({ id, first_name, last_name, fullName = `${first_name} ${last_name}`, email }) => (
                        { value: id, label: `${fullName} <${email}>` }
                    ))
                )}
            />
            <TextInput
                value={subjectText}
                label="Email Subject line"
                onChange={handleChangeSubject}
                fullWidth
                className="u-mrg--bx5"
                margin="none"
                maxLength={500}
                hasCounter
                variant="outlined"
                disabled={!edit}
                helperText={!subjectText ? <span>Email subject line is required.</span> : <span />}
                isError={!subjectText}
            />
            <MessageEditForm
                form={form}
                setForm={setForm}
                errorForm={errorForm}
                setErrorForm={setError}
                opportunity={opportunity}
                type={type}
                company={company}
                columnType={columnType}
                maxCharacters={MAX_CHAR}
                assessmentBrand={assessmentBrand}
            />
        </Dialog>
    );
});

export default withStyles(styles)(PreviewMessageDialog);
