import React from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import Tooltip from 'libraries/Tooltip';
import CompareIcon from 'components/icons/CompareIcon';
import { MAX_CANDIDATES_COMPARE_COUNT } from 'helper/constants';
import styles from './styles';


const CompareButton = ({ classes, onClick, count, isActive, showCount, disabled, canCompareCandidates }) => {
    let buttonDisabled = disabled;
    let tooltipLabel = <span>Add candidates to compare <br /> results side by side</span>;
    if (!canCompareCandidates) {
        buttonDisabled = true;
        tooltipLabel = <span>Compare is not available for assessments with turned on group randomization with the limit number of questions.</span>;
    } else if (count >= MAX_CANDIDATES_COMPARE_COUNT && !showCount && !isActive) {
        buttonDisabled = true;
        tooltipLabel = <span>Maximum candidates limit in compare reached. Remove candidate from compare to add new.</span>;
    }

    return (
        <Tooltip
            label={tooltipLabel}
            delayHide={300}
            delayShow={300}
        >
            <div>
                <Button
                    onClick={onClick}
                    disabled={buttonDisabled}
                    className={classes.compareButton}
                >
                    <CompareIcon isActive={isActive} />&nbsp;
                    Compare <span>{Boolean(count) && showCount && `(${count})`}</span>
                </Button>
            </div>
        </Tooltip>
    );
};

export default withStyles(styles)(CompareButton);
