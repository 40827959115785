import React, { PureComponent } from 'react';
import Dialog from 'libraries/Dialog';
import Button from '@mui/material/Button';
import SnackbarProgress from 'libraries/SnackbarProgress';
import { checkPluralNew } from 'helper/commonFunctions';
import { timeToCloseFlashMessageCancel, BATCH_STEP } from 'helper/constants';
import { extendCandidates } from 'requests/AssessmentRequests';
import AssessmentEvents from 'events/AssessmentEvents';
import { appCtx } from '../../appStore';

class ExtendMultipleAssessmentUsers extends PureComponent {
    static contextType = appCtx;

    state = {
        onLoading: false,
        canceled: false,
        progress: 0,
        error: false
    };

    handleError = (error) => {
        const { flashMessage } = this.context;
        const { handleClose } = this.props;
        this.setState({ error: true, onLoading: false });
        if (error) {
            flashMessage(error);
        }
        handleClose();
    }

    extendUsers = async () => {
        const { userAuditionIds, audition } = this.props;
        this.setState({ onLoading: true, error: false });
        const { uuid: ttId, name: ttName } = audition;

        AssessmentEvents.CANDIDATES_BATCH_ACTIONED({
            ttId,
            ttName,
            actionType: 'extend',
            numberCandidatesActioned: userAuditionIds.length
        });

        const stepsNeeded = Math.ceil(userAuditionIds.length / BATCH_STEP);

        this.extendCandidatesFunc(1, stepsNeeded);
    };

    extendCandidatesFunc = (currentStep = 1, stepsNeeded) => {
        const { canceled } = this.state;
        const { onSuccess, handleClose, userAuditionIds, slug } = this.props;
        const nextStartIndex = (currentStep - 1) * BATCH_STEP;
        const nextLastIndex = nextStartIndex + BATCH_STEP;
        return extendCandidates(slug, userAuditionIds.slice(nextStartIndex, nextLastIndex))
            .then(({ success }) => {
                if (success && !canceled) {
                    if (currentStep === stepsNeeded) {
                        handleClose();
                        this.setState({ progress: 100 });
                        setTimeout(() => {
                            if (onSuccess) {
                                onSuccess();
                            }
                            this.setState({ onLoading: false });
                        }, 1000);
                    } else if (currentStep < stepsNeeded) {
                        this.setState({ progress: Math.floor(currentStep / stepsNeeded * 100) });
                        this.extendCandidatesFunc(currentStep + 1, stepsNeeded);
                    }
                } else if (!canceled) {
                    this.handleError();
                }
            })
            .catch((e) => {
                this.handleError(e?.response?.data?.msg);
            });
    };

    handleCancel = () => {
        this.setState({ canceled: true }, () => {
            const { handleClose } = this.props;
            this.setState({ onLoading: false });
            setTimeout(handleClose, timeToCloseFlashMessageCancel);
        });
    };

    render() {
        const { handleClose, userAuditionIds, open, audition } = this.props;
        const { onLoading, progress, error, canceled } = this.state;
        const usersCount = userAuditionIds.length;
        const candidateFormatted = `${usersCount} ${checkPluralNew(usersCount, 'candidate')}`;
        const { containsCyberSimulatorQuestions } = audition;

        if (onLoading) {
            return (
                <SnackbarProgress
                    open
                    showSuccessIcon
                    message={`Extending deadline for ${candidateFormatted}`}
                    onCancel={this.handleCancel}
                    percents={progress}
                    error={error}
                    errorLabel="ERROR - INTERNET CONNECTION TIMED OUT"
                    canceled={canceled}
                />
            );
        }

        return (
            <>
                <Dialog
                    open={open}
                    maxWidth="sm"
                    actionComponent={(
                        <>
                            <Button
                                color="primary"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={usersCount === 0 || onLoading}
                                onClick={this.extendUsers}
                            >
                                Extend deadline for <span>{candidateFormatted}</span>
                            </Button>
                        </>
                    )}
                    titleComponent={<>Extend Deadline for {usersCount} {checkPluralNew(usersCount, 'Candidate')}</>}
                    handleClose={handleClose}
                    onClose={handleClose}
                >
                    This option will extend the deadline for the selected candidates and reset any question timers.&nbsp;
                    {
                        containsCyberSimulatorQuestions
                            ? <span>Extending the deadline will reset a candidate’s input for all terminal based question types (like Cyber Simulations), but other questions will retain their input. Candidates&nbsp;</span>
                            : <span>They&nbsp;</span>
                    }
                    will be notified of this extension and will have extra time to complete the Skills Assessment.
                </Dialog>
            </>
        );
    }
}

export default ExtendMultipleAssessmentUsers;
